import { Divider, Form, Input, Modal } from 'antd';
import { capitalize } from 'lodash';
import React, { useEffect } from 'react';
import { PERMISSION_TYPE } from '../../../common/constants';
import { normalizeNegativeNumber } from '../../../common/utils';
import { Switch } from '../../pages/component/pageModules/moduleForms/FormInputs';
import { GET_UI_LABELS } from '../../ui-labels/graphql/Queries';
import { Select } from '../../videos/components/FormInputs';

const PermissionsModal = ({
  openModal,
  setOpenModal,
  record,
  setPermissionsValues,
  permissionsValues
}) => {
  const [form] = Form.useForm();
  const type = permissionsValues?.[record?.key]?.permissionType;
  const applyLimit = Form.useWatch(
    ['permissions', record?.key, 'applyLimit'],
    form
  );
  const handleFinish = (values) => {
    setPermissionsValues({
      ...permissionsValues,
      [record?.key]: {
        ...values?.permissions?.[record?.key]
      }
    });
    setOpenModal(false);
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (permissionsValues?.[record?.key]) {
      form.setFieldsValue({
        permissions: {
          [record?.key]: permissionsValues?.[record?.key]
        }
      });
    }
  }, [permissionsValues, record]);

  return (
    <Modal
      open={openModal}
      onCancel={handleCancel}
      centered
      title={capitalize(record?.key?.split('_')?.join(' '))}
      onOk={form?.submit}
      okText="Apply"
    >
      <Form
        layout="vertical"
        onFinish={handleFinish}
        form={form}
        initialValues={{
          permissions: {
            [record?.key]: {
              allowed: true,
              applyLimit: false,
              limit: 0,
              frequency: 0,
              errorMessage: null,
              limitMessage: null
            }
          }
        }}
      >
        <Form.Item
          name={['permissions', record?.key, 'allowed']}
          valuePropName="checked"
        >
          <Switch label="Allowed" />
        </Form.Item>
        <Form.Item
          name={['permissions', record?.key, 'errorMessage']}
          label="Error Message"
          rules={[{ required: true, message: 'Please select error message!' }]}
        >
          <Select
            placeholder="Select message"
            query={GET_UI_LABELS}
            variablesSelector={(filter) => ({
              filter: { labelType: 'MESSAGE', ...filter }
            })}
            dataSelector={(data) =>
              data?.uiLabelsAdmin?.uiLabels?.map(({ key }) => ({
                label: key,
                value: key
              })) ?? 0
            }
            keys={{
              data: 'uiLabelsAdmin',
              records: 'uiLabels',
              count: 'count'
            }}
          />
        </Form.Item>
        <Divider />
        <Form.Item
          name={['permissions', record?.key, 'applyLimit']}
          valuePropName="checked"
        >
          <Switch
            label="Apply Limit"
            disabled={type === PERMISSION_TYPE.READ}
          />
        </Form.Item>
        {applyLimit && (
          <>
            <Form.Item
              name={['permissions', record?.key, 'frequency']}
              label="Frequency"
              normalize={normalizeNegativeNumber}
            >
              <Input
                type="number"
                placeholder="Enter frequency"
                className="number-input"
              />
            </Form.Item>
            <Form.Item
              name={['permissions', record?.key, 'limit']}
              label="Limit"
              normalize={normalizeNegativeNumber}
            >
              <Input
                placeholder="Enter limit"
                type="number"
                className="number-input"
              />
            </Form.Item>
            <Form.Item
              name={['permissions', record?.key, 'limitMessage']}
              label="Limit Message"
              rules={[
                { required: true, message: 'Please select limit message!' }
              ]}
            >
              <Select
                placeholder="Select message"
                query={GET_UI_LABELS}
                variablesSelector={(filter) => ({
                  filter: { labelType: 'MESSAGE', ...filter }
                })}
                dataSelector={(data) =>
                  data?.uiLabelsAdmin?.uiLabels?.map(({ key }) => ({
                    label: key,
                    value: key
                  })) ?? 0
                }
                keys={{
                  data: 'uiLabelsAdmin',
                  records: 'uiLabels',
                  count: 'count'
                }}
              />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default PermissionsModal;
