import { EditOutlined, EyeFilled, EyeOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Flex, Select, Tooltip } from 'antd';
import { capitalize } from 'lodash';
import { Export } from 'phosphor-react';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  FORM_TYPES,
  FORM_TYPE_OPTIONS,
  LIST_TYPES,
  MODULES,
  ROUTES,
  STATUS_OPTIONS,
  STATUS_TYPES,
  UNPUBLISHED_STATUS,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import useCheckPermission from '../../hooks/useCheckPermission';
import { REQUEST_FORM_RESPONSE_EXPORT } from './graphql/Mutations';
import { GET_FORMS } from './graphql/Queries';

const variablesSelector = ({
  offset,
  sortField,
  sortOrder,
  search,
  limit,
  type,
  status
}) => ({
  filter: {
    skip: offset,
    search,
    limit,
    type,
    status
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = (data) => ({
  data: data?.formsAdmin?.forms ?? [],
  count: data?.formsAdmin?.count ?? 0
});

const Action = ({ id, type }) => {
  const history = useHistory();

  const [request, { loading: isExporting }] = useMutation(
    REQUEST_FORM_RESPONSE_EXPORT,
    {
      onError() {},
      variables: {
        where: {
          id
        }
      }
    }
  );

  const handleVideResponses = () => {
    history.push(`${ROUTES.FORMS}/${id}/responses?type=${type}`);
  };

  const isExportAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.REPORT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const handleExport = () => {
    request();
  };

  const handleEdit = () => {
    history.push({
      pathname: `${ROUTES.FORMS}/${id}/edit`
    });
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  return (
    <Flex align="center">
      <Tooltip title="View Responses">
        <Button
          className="text-btn d-flex align-center justify-center"
          type="text"
          onClick={handleVideResponses}
        >
          <EyeOutlined size={20} />
        </Button>
      </Tooltip>
      <Tooltip title={`${isViewOnly ? 'View' : 'Edit'} Form`}>
        <Button type="text" className="text-btn" onClick={handleEdit}>
          {isViewOnly ? <EyeFilled /> : <EditOutlined />}
        </Button>
      </Tooltip>
      {isExportAllowed && (
        <Tooltip title="Export Responses" placement="left">
          <Button
            className="text-btn d-flex align-center justify-center"
            type="text"
            icon={<Export size={24} />}
            onClick={handleExport}
            loading={isExporting}
          />
        </Tooltip>
      )}
    </Flex>
  );
};

const columns = ({ isAddEditAllowed, type }) => [
  {
    title: 'Title',
    dataIndex: 'title',
    ellipsis: true
  },
  {
    title: 'Description',
    dataIndex: 'description',
    ellipsis: true
  },
  {
    title: 'Type',
    dataIndex: 'type',
    ellipsis: true,
    render: (value) => {
      return <p>{capitalize(value)}</p>;
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    ellipsis: true,
    render: (value) => {
      return {
        [STATUS_TYPES.DRAFT]: 'Draft',
        [STATUS_TYPES.PUBLISHED]: 'Published',
        [STATUS_TYPES.UNPUBLISHED]: 'Unlisted'
      }[value];
    }
  },
  {
    title: 'Action',
    dataIndex: 'id',
    render: (_, record) => (
      <Action {...record} isAddEditAllowed={isAddEditAllowed} type={type} />
    )
  }
];

const FormList = ({ history }) => {
  const filterProps = useFilterBar();
  const [type, setType] = useState(FORM_TYPES.PETITION);
  const [status, setStatus] = useState(undefined);

  const handleTypeChange = (value) => setType(value);
  const handleStatusChange = (value) => setStatus(value);

  const filters = useMemo(() => ({ ...filterProps.filters, type, status }), [
    filterProps.filters,
    type,
    status
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const handleShowModal = () => {
    history.push(`${ROUTES.FORMS}/add`);
  };

  return (
    <>
      <PageHeader
        menu={MODULES.CONTENT_UNITS}
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed}
      />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Form' }}
        show={{
          listModes: false
        }}
      >
        <Select
          value={type}
          popupMatchSelectWidth={false}
          placeholder="Select type"
          options={FORM_TYPE_OPTIONS}
          onChange={handleTypeChange}
        />
        <Select
          options={[...STATUS_OPTIONS, UNPUBLISHED_STATUS].map(
            ({ name, value }) => ({
              label: name,
              value
            })
          )}
          placeholder="Select status"
          value={status}
          onChange={handleStatusChange}
        />
      </FilterBar>
      <PageList
        filters={filters}
        listMode={LIST_TYPES.TABLE}
        query={GET_FORMS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{
          columns: (props) =>
            columns({
              ...props,
              isAddEditAllowed,
              type
            })?.filter((item) => item !== false)
        }}
        limit={20}
      />
    </>
  );
};

export default FormList;
