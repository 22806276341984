import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import AccessControl from '../../../components/AccessControl';
import AddEditSource from './AddEditSource';
import Sources from './Sources';

function SourcesWrapper() {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES?.SOURCES}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <Sources {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.SOURCES}/add`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditSource {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.SOURCES}/:sourceId/edit`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditSource {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.SOURCES}/:sourceId`}
        render={() => <Redirect to={ROUTES?.SOURCES} />}
      />
    </Switch>
  );
}

export default SourcesWrapper;
