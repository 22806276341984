import { useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Form,
  Input,
  Space,
  Typography
} from 'antd';
import React, { useEffect, useMemo } from 'react';
import {
  AUTO_GENERATED_OPTIONS,
  AUTO_GENERATED_TYPES,
  CONFIG_METHOD_OPTIONS,
  CONFIG_METHOD_TYPES,
  MODULE_TYPES,
  PAGE_TYPES,
  STATIC_DATA_KEYS,
  STATUS_TYPES,
  TEMPLATE_OPTION,
  getAutoGenerateByDataValue,
  getAutoGeneratedByIdProps
} from '../../../../../common/constants';
import {
  formValidatorRules,
  normalizeNegativeNumber
} from '../../../../../common/utils';
import useStaticData from '../../../../../hooks/useStaticData';
import {
  SelectArticle,
  SelectArticleWithFeatured
} from '../../../../labels/collections/components/FormInputs';
import { GET_COLLECTION_ITEMS } from '../../../../labels/collections/graphql/Queries';
import { Select } from '../../../../videos/components/FormInputs';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ModuleFields, Permissions, ShowFields, Switch } from './FormInputs';

const AUTO_GENERATED_SORT_BY_TYPES = {
  RECENT: 'RECENT',
  RANDOM: 'RANDOM',
  READS: 'READS'
};

const ARTICLE_GENERATED_TYPE = {
  CONTRIBUTOR: 'CONTRIBUTOR',
  COLLECTION: 'COLLECTION',
  TAG: 'TAG',
  TOPIC: 'TOPIC',
  SOURCE: 'SOURCE'
};
const CONFIG_TITLE = {
  [MODULE_TYPES.ARTICLE_LIST]: 'Article List',
  [MODULE_TYPES.ARTICLE_LIST_V2]: 'Article List V2',
  [MODULE_TYPES.ARTICLE_CAROUSEL]: 'Article Carousel',
  [MODULE_TYPES.ARTICLE_CAROUSEL_V2]: 'Article Carousel V2',
  [MODULE_TYPES.FEATURED_ARTICLE]: 'Featured Article',
  [MODULE_TYPES.FEATURED_ARTICLE_V2]: 'Featured Article V2',
  [MODULE_TYPES.FEATURED_ARTICLE_SLIDER]: 'Featured Article Slider',
  [MODULE_TYPES.ARTICLE_GRID]: 'Article Grid',
  [MODULE_TYPES.ARTICLE_FEATURED_CAROUSEL]: 'Article Carousel with Featured',
  [MODULE_TYPES.LOOP_ARTICLE_LIST]: 'Loop Article List',
  [MODULE_TYPES.ARTICLE_DISPLAY]: 'Article Display'
};

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_CAROUSEL,
      MODULE_TYPES.ARTICLE_FEATURED_CAROUSEL,
      MODULE_TYPES.ARTICLE_GRID,
      MODULE_TYPES.ARTICLE_LIST,
      MODULE_TYPES.FEATURED_ARTICLE_SLIDER
    ]
  },
  {
    name: 'viewAll',
    label: 'View All Button',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_CAROUSEL,
      MODULE_TYPES.ARTICLE_FEATURED_CAROUSEL,
      MODULE_TYPES.ARTICLE_GRID,
      MODULE_TYPES.ARTICLE_LIST
    ]
  },
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_CAROUSEL,
      MODULE_TYPES.ARTICLE_CAROUSEL_V2,
      MODULE_TYPES.ARTICLE_LIST,
      MODULE_TYPES.ARTICLE_LIST_V2,
      MODULE_TYPES.ARTICLE_GRID,
      MODULE_TYPES.FEATURED_ARTICLE_SLIDER,
      MODULE_TYPES.ARTICLE_FEATURED_CAROUSEL
    ]
  },
  {
    name: 'articleAuthor',
    label: 'Authors',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_CAROUSEL,
      MODULE_TYPES.ARTICLE_LIST,
      MODULE_TYPES.ARTICLE_LIST_V2,
      MODULE_TYPES.ARTICLE_CAROUSEL_V2,
      MODULE_TYPES.FEATURED_ARTICLE,
      MODULE_TYPES.FEATURED_ARTICLE_V2,
      MODULE_TYPES.ARTICLE_GRID,
      MODULE_TYPES.FEATURED_ARTICLE_SLIDER,
      MODULE_TYPES.ARTICLE_FEATURED_CAROUSEL,
      MODULE_TYPES.ARTICLE_DISPLAY
    ]
  },
  {
    name: 'articleTopic',
    label: 'Topics',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_CAROUSEL,
      MODULE_TYPES.ARTICLE_CAROUSEL_V2,
      MODULE_TYPES.ARTICLE_LIST,
      MODULE_TYPES.ARTICLE_LIST_V2,
      MODULE_TYPES.FEATURED_ARTICLE,
      MODULE_TYPES.FEATURED_ARTICLE_V2,
      MODULE_TYPES.ARTICLE_GRID,
      MODULE_TYPES.FEATURED_ARTICLE_SLIDER,
      MODULE_TYPES.ARTICLE_FEATURED_CAROUSEL,
      MODULE_TYPES.ARTICLE_DISPLAY
    ]
  },
  {
    name: 'articleReactions',
    label: 'Reactions',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_CAROUSEL,
      MODULE_TYPES.ARTICLE_LIST,
      MODULE_TYPES.FEATURED_ARTICLE,
      MODULE_TYPES.FEATURED_ARTICLE_V2,
      MODULE_TYPES.ARTICLE_GRID,
      MODULE_TYPES.FEATURED_ARTICLE_SLIDER,
      MODULE_TYPES.ARTICLE_CAROUSEL_V2,
      MODULE_TYPES.ARTICLE_LIST_V2,
      MODULE_TYPES.ARTICLE_FEATURED_CAROUSEL,
      MODULE_TYPES.ARTICLE_DISPLAY
    ]
  },
  {
    name: 'articleTimestamp',
    label: 'Timestamp',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_CAROUSEL_V2,
      MODULE_TYPES.ARTICLE_LIST_V2
    ]
  },
  {
    name: 'articleDate',
    label: 'Article Date',
    allowedTypes: [
      MODULE_TYPES.FEATURED_ARTICLE,
      MODULE_TYPES.FEATURED_ARTICLE_V2,
      MODULE_TYPES.ARTICLE_CAROUSEL,
      MODULE_TYPES.ARTICLE_FEATURED_CAROUSEL,
      MODULE_TYPES.ARTICLE_GRID,
      MODULE_TYPES.ARTICLE_LIST,
      MODULE_TYPES.FEATURED_ARTICLE_SLIDER,
      MODULE_TYPES.ARTICLE_DISPLAY
    ]
  },
  {
    name: 'articleSource',
    label: 'Source',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_FEATURED_CAROUSEL,
      MODULE_TYPES.ARTICLE_DISPLAY
    ]
  },
  {
    name: 'articleKebabMenu',
    label: 'Kebab Menu',
    allowedTypes: [MODULE_TYPES.ARTICLE_FEATURED_CAROUSEL]
  },
  {
    name: 'articlePray',
    label: 'Article Pray',
    allowedTypes: [MODULE_TYPES.ARTICLE_DISPLAY]
  }
];
const AUTO_GENERATED_SORT_BY_TYPES_OPTIONS = [
  { label: 'Recent', value: AUTO_GENERATED_SORT_BY_TYPES.RECENT },
  { label: 'Random', value: AUTO_GENERATED_SORT_BY_TYPES.RANDOM },
  { label: 'Reads', value: AUTO_GENERATED_SORT_BY_TYPES.READS }
];

const getInitialValues = (type) => ({
  title: '',
  description: '',
  permissions: [],
  status: STATUS_TYPES.PUBLISHED,
  isDefaultModule: false,
  settings: {
    title: true,
    viewAll: true,
    articleAuthor: true,
    articleComment: true,
    articleShare: true,
    articlePrayer: true,
    articleTimestamp: true,
    articleTopic: true,
    description: true,
    articleDate: true,
    relatedContent: true,
    articleSave: true,
    articleHtml: true,
    articleReactions: true,
    articleSource: true,
    articleKebabMenu: true,
    articlePray: true
  },
  config: {
    method: null,
    autoGenerateById: '',
    autoGenerateByType: ARTICLE_GENERATED_TYPE[0],
    autoGenerateSortBy: AUTO_GENERATED_SORT_BY_TYPES[0],
    autoGenerateLimit: null,
    deduplication: false,
    pastContent: false,
    articles: [
      MODULE_TYPES.FEATURED_ARTICLE,
      MODULE_TYPES.FEATURED_ARTICLE_V2,
      MODULE_TYPES.ARTICLE_DISPLAY
    ]?.includes(type)
      ? { id: '', url: '', title: '' }
      : [],
    pastContentDays: null
  }
});

const { ARTICLE } = PAGE_TYPES;
const REST_PAGE_TYPES = [
  PAGE_TYPES.TOPIC,
  PAGE_TYPES.TAG,
  PAGE_TYPES.CONTRIBUTOR,
  PAGE_TYPES.COLLECTION
];

const ALLOWED_TEMPLATE_CONFIGS = {
  [MODULE_TYPES.ARTICLE_LIST]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.ARTICLE_CAROUSEL]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.ARTICLE_CAROUSEL_V2]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.FEATURED_ARTICLE]: [ARTICLE, ...REST_PAGE_TYPES],
  [MODULE_TYPES.FEATURED_ARTICLE_V2]: [ARTICLE, ...REST_PAGE_TYPES],
  [MODULE_TYPES.LOOP_ARTICLE_LIST]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.ARTICLE_DISPLAY]: [ARTICLE, ...REST_PAGE_TYPES]
};

const MODULE_KEYS = {
  [MODULE_TYPES.ARTICLE_LIST]: 'articleListModule',
  [MODULE_TYPES.ARTICLE_LIST_V2]: 'articleListModuleV2',
  [MODULE_TYPES.ARTICLE_CAROUSEL]: 'articleCarouselModule',
  [MODULE_TYPES.ARTICLE_CAROUSEL_V2]: 'articleCarouselModuleV2',
  [MODULE_TYPES.FEATURED_ARTICLE]: 'featuredArticleModule',
  [MODULE_TYPES.FEATURED_ARTICLE_V2]: 'featuredArticleModuleV2',
  [MODULE_TYPES.ARTICLE_GRID]: 'articleGridModule',
  [MODULE_TYPES.FEATURED_ARTICLE_SLIDER]: 'featuredArticleSliderModule',
  [MODULE_TYPES.ARTICLE_FEATURED_CAROUSEL]: 'articleFeaturedCarouselModule',
  [MODULE_TYPES.LOOP_ARTICLE_LIST]: 'loopArticleListModule',
  [MODULE_TYPES.ARTICLE_DISPLAY]: 'articleDisplayModule'
};

function ArticleForm({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  isDefaultPage,
  pageType
}) {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;
  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );
  const { data: configData } = useStaticData(STATIC_DATA_KEYS.CONFIGS);

  const isMultiArticle = ![
    MODULE_TYPES.FEATURED_ARTICLE,
    MODULE_TYPES.FEATURED_ARTICLE_V2,
    MODULE_TYPES.ARTICLE_DISPLAY
  ]?.includes(type);
  const {
    autoGenerateByType,
    method,
    pastContent,
    autoGenerateById: generateById
  } = Form.useWatch(['config'], form) ?? {};
  const collectionId = generateById?.value;
  const isDefaultModule = Form.useWatch(['isDefaultModule'], form);

  const allowedTemplateConfig =
    isDefaultPage && ALLOWED_TEMPLATE_CONFIGS[type]?.includes(pageType);
  const autoGenerateByIdProps = getAutoGeneratedByIdProps[autoGenerateByType];

  const includeOptions = useMemo(
    () => (autoGenerateByType === pageType ? [TEMPLATE_OPTION] : []),
    [autoGenerateByType, pageType]
  );
  const initialValues = useMemo(() => getInitialValues(type), [type]);
  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      const idm = defaultValues?.isDefaultModule;
      const values = {
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        isDefaultModule: idm,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          method:
            defaultValues?.moduleData?.config?.method ??
            defaultValues?.moduleData?.config?.loopConfigMethod ??
            null,
          autoGenerateByType:
            defaultValues?.moduleData?.config?.articleAutoGenerateByType ||
            null,
          autoGenerateById: idm
            ? TEMPLATE_OPTION
            : getAutoGenerateByDataValue[
                defaultValues?.moduleData?.config?.articleAutoGenerateByType
              ]?.(defaultValues?.moduleData?.config?.autoGenerateByData) ??
              null,
          autoGenerateBySubCollectionId:
            defaultValues?.moduleData?.config
              ?.autoGenerateBySubCollectionData === null
              ? { label: 'All', value: 'all' }
              : {
                  label:
                    defaultValues?.moduleData?.config
                      ?.autoGenerateBySubCollectionData?.title,
                  value:
                    defaultValues?.moduleData?.config
                      ?.autoGenerateBySubCollectionData?.id
                },
          autoGenerateSortBy:
            defaultValues?.moduleData?.config?.articleAutoGenerateSortBy ||
            null,
          autoGenerateLimit:
            defaultValues?.moduleData?.config?.autoGenerateLimit || null,
          articles: !isMultiArticle
            ? {
                id: defaultValues?.moduleData?.config?.article?.id,
                title: defaultValues?.moduleData?.config?.article?.title,
                url:
                  defaultValues?.moduleData?.config?.article?.imageThumbnail
                    ?.url,
                ...(type === MODULE_TYPES.ARTICLE_FEATURED_CAROUSEL && {
                  isFeatured:
                    defaultValues?.moduleData?.config?.article?.isFeatured
                })
              }
            : defaultValues?.moduleData?.config?.articles?.map(
                ({ id, title, imageThumbnail, isFeatured }) => ({
                  id,
                  title,
                  url: imageThumbnail?.url ?? '',
                  ...(type === MODULE_TYPES.ARTICLE_FEATURED_CAROUSEL && {
                    isFeatured
                  })
                })
              ),
          deduplication:
            defaultValues?.moduleData?.config?.deduplication ?? false,
          pastContent: defaultValues?.moduleData?.config?.pastContent ?? false,
          pastContentDays:
            defaultValues?.moduleData?.config?.pastContentDays || null
        }
      };
      form.setFieldsValue(values);
    }
  }, [
    form,
    moduleId,
    formType,
    defaultValues,
    form,
    initialValues,
    type,
    isMultiArticle
  ]);

  const handleSubmit = (values) => {
    // eslint-disable-next-line no-shadow
    const { config, settings, permissions, isDefaultModule, ...rest } =
      values ?? {};
    const key = MODULE_KEYS[type];

    const {
      articles,
      autoGenerateById = '',
      autoGenerateLimit,
      method: articleMethod,
      pastContent: articlePastContent,
      deduplication,
      pastContentDays,
      autoGenerateBySubCollectionId,
      ...restConfig
    } = config ?? {};

    const isDefault = allowedTemplateConfig && isDefaultModule;

    if (!key) return;
    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config:
          type === MODULE_TYPES.LOOP_ARTICLE_LIST
            ? {
                method: articleMethod ?? CONFIG_METHOD_TYPES.AUTO_GENERATED,
                autoGenerateLimit: Number(autoGenerateLimit),
                pastContent: articlePastContent,
                pastContentDays: Number(pastContentDays)
              }
            : {
                ...restConfig,
                ...(isDefault
                  ? {
                      articleId: '*'
                    }
                  : {
                      ...(!isMultiArticle
                        ? {
                            articleId: articles?.id
                          }
                        : {
                            articles:
                              articles?.map(({ id, isFeatured }, i) => ({
                                articleId: id,
                                order: i + 1,
                                ...(type ===
                                  MODULE_TYPES.ARTICLE_FEATURED_CAROUSEL && {
                                  isFeatured
                                })
                              })) ?? []
                          })
                    }),
                method: articleMethod ?? CONFIG_METHOD_TYPES.MANUAL,
                ...(isMultiArticle && {
                  autoGenerateLimit: autoGenerateLimit
                    ? Number(autoGenerateLimit)
                    : null
                }),
                autoGenerateById: isEdit
                  ? autoGenerateById?.value
                  : autoGenerateById?.key ?? null,
                ...(articlePastContent && {
                  pastContent: articlePastContent
                }),
                ...(articleMethod === CONFIG_METHOD_TYPES.AUTO_GENERATED &&
                  autoGenerateBySubCollectionId && {
                    autoGenerateBySubCollectionId:
                      autoGenerateBySubCollectionId?.value === 'all'
                        ? null
                        : autoGenerateBySubCollectionId?.value
                  }),
                ...(![
                  MODULE_TYPES.ARTICLE_GRID,
                  MODULE_TYPES.ARTICLE_CAROUSEL,
                  MODULE_TYPES.ARTICLE_DISPLAY
                ]?.includes(type) && {
                  pastContentDays: pastContentDays
                    ? Number(pastContentDays)
                    : null
                }),
                ...(deduplication && {
                  deduplication
                })
              }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValues}
      >
        <ModuleFields />
        <Space className="w-full" direction="vertical">
          <Space className="w-full" direction="vertical">
            <ShowFields settings={SETTINGS} type={type} />
            {allowedTemplateConfig && pageType === ARTICLE && (
              <>
                <Typography.Text>Template Config</Typography.Text>
                <Form.Item
                  className="m-0"
                  name="isDefaultModule"
                  valuePropName="checked"
                >
                  <Switch label="Use Template Data" />
                </Form.Item>
              </>
            )}
            {(!allowedTemplateConfig ||
              (allowedTemplateConfig && !isDefaultModule)) && (
              <>
                <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
                <Form.Item label="Config Method" name={['config', 'method']}>
                  <AntdSelect
                    options={
                      type === MODULE_TYPES.LOOP_ARTICLE_LIST
                        ? CONFIG_METHOD_OPTIONS?.filter(
                            ({ value }) =>
                              value === CONFIG_METHOD_TYPES.AUTO_GENERATED
                          )
                        : CONFIG_METHOD_OPTIONS
                    }
                    placeholder="Select method"
                  />
                </Form.Item>
                {method === CONFIG_METHOD_TYPES.AUTO_GENERATED && (
                  <>
                    {type !== MODULE_TYPES.LOOP_ARTICLE_LIST && (
                      <>
                        <Form.Item
                          label="Auto Generated By Type"
                          name={['config', 'autoGenerateByType']}
                        >
                          <AntdSelect
                            options={AUTO_GENERATED_OPTIONS?.concat([
                              {
                                label: 'News',
                                value: AUTO_GENERATED_TYPES.NEWS
                              },
                              {
                                label: 'Article',
                                value: AUTO_GENERATED_TYPES.ARTICLE
                              },
                              {
                                label: 'Lifestyle',
                                value: AUTO_GENERATED_TYPES.LIFESTYLE
                              }
                            ])}
                            placeholder="Select type"
                            onChange={() => {
                              form.setFieldValue(
                                ['config', 'autoGenerateById'],
                                null
                              );
                            }}
                          />
                        </Form.Item>
                        {autoGenerateByType &&
                          autoGenerateByIdProps &&
                          ![
                            AUTO_GENERATED_TYPES.ARTICLE,
                            AUTO_GENERATED_TYPES.NEWS,
                            AUTO_GENERATED_TYPES.LIFESTYLE
                          ]?.includes(autoGenerateByType) && (
                            <Form.Item
                              label="Auto Generated By"
                              name={['config', 'autoGenerateById']}
                            >
                              <Select
                                placeholder="Select"
                                query={autoGenerateByIdProps?.query}
                                variablesSelector={({
                                  skip,
                                  limit,
                                  search
                                }) => ({
                                  filter: {
                                    skip,
                                    limit,
                                    search
                                  },
                                  ...(autoGenerateByType ===
                                    AUTO_GENERATED_TYPES.CONTRIBUTOR && {
                                    where: { isActive: true }
                                  })
                                })}
                                dataSelector={
                                  autoGenerateByIdProps?.dataSelector
                                }
                                keys={autoGenerateByIdProps?.keys}
                                {...(allowedTemplateConfig && {
                                  includeOptions
                                })}
                              />
                            </Form.Item>
                          )}
                        {autoGenerateByType ===
                          AUTO_GENERATED_TYPES?.COLLECTION &&
                          collectionId && (
                            <Form.Item
                              label="Select SubCollection"
                              name={['config', 'autoGenerateBySubCollectionId']}
                            >
                              <Select
                                placeholder="Select sub collection"
                                query={GET_COLLECTION_ITEMS}
                                variablesSelector={() => ({
                                  where: {
                                    id: collectionId
                                  }
                                })}
                                dataSelector={(data) =>
                                  data?.collectionItemsAdmin?.collectionItems?.map(
                                    (item) => ({
                                      label: item?.itemData?.title,
                                      value: item?.itemData?.id
                                    })
                                  ) ?? []
                                }
                                includeOptions={[
                                  { label: 'All', value: 'all' }
                                ]}
                                keys={{
                                  data: 'collectionItemsAdmin',
                                  records: 'collectionItems',
                                  count: 'count'
                                }}
                              />
                            </Form.Item>
                          )}
                        <Form.Item
                          label="Auto Generated Sort By"
                          name={['config', 'autoGenerateSortBy']}
                        >
                          <AntdSelect
                            options={AUTO_GENERATED_SORT_BY_TYPES_OPTIONS}
                            placeholder="Select sort by"
                          />
                        </Form.Item>
                      </>
                    )}
                  </>
                )}
                {[
                  CONFIG_METHOD_TYPES.AUTO_GENERATED,
                  CONFIG_METHOD_TYPES.RECOMMENDED
                ]?.includes(method) && (
                  <>
                    {isMultiArticle && (
                      <Form.Item
                        label="No. of Auto generated Articles"
                        name={['config', 'autoGenerateLimit']}
                        rules={[
                          formValidatorRules?.number,
                          formValidatorRules?.maxNumberAllowed(
                            configData?.MAX_AUTO_GENERATE_LIMIT?.value || 20
                          )
                        ]}
                      >
                        <Input placeholder="Enter number" />
                      </Form.Item>
                    )}
                  </>
                )}
                {method === CONFIG_METHOD_TYPES.MANUAL && (
                  <Form.Item
                    label={
                      isMultiArticle ? 'Select Articles' : 'Select Article'
                    }
                    name={['config', 'articles']}
                    extra={
                      isMultiArticle ? 'Select items in order you want' : ''
                    }
                  >
                    {type === MODULE_TYPES.ARTICLE_FEATURED_CAROUSEL ? (
                      <SelectArticleWithFeatured multiple={isMultiArticle} />
                    ) : (
                      <SelectArticle multiple={isMultiArticle} />
                    )}
                  </Form.Item>
                )}
                {method === CONFIG_METHOD_TYPES.AUTO_GENERATED && (
                  <>
                    {[MODULE_TYPES.ARTICLE_LIST]?.includes(type) && (
                      <Form.Item
                        className="m-0"
                        name={['config', 'deduplication']}
                        valuePropName="checked"
                      >
                        <Switch label="Deduplication" />
                      </Form.Item>
                    )}
                    {![
                      MODULE_TYPES.ARTICLE_GRID,
                      MODULE_TYPES.ARTICLE_CAROUSEL,
                      MODULE_TYPES.ARTICLE_DISPLAY
                    ]?.includes(type) && (
                      <>
                        <Form.Item
                          className="m-0"
                          name={['config', 'pastContent']}
                          valuePropName="checked"
                        >
                          <Switch label="Past Content" />
                        </Form.Item>
                        {pastContent && (
                          <Form.Item
                            name={['config', 'pastContentDays']}
                            label="Past Content Days"
                            normalize={normalizeNegativeNumber}
                          >
                            <Input
                              type="number"
                              placeholder="Enter past content days"
                              className="number-input"
                            />
                          </Form.Item>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <Permissions />
            <div className="d-flex button-section">
              <Space>
                <Form.Item>
                  <Button
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                    disabled={loading}
                    loading={loading}
                  >
                    Save
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button
                    disabled={loading}
                    onClick={onCancel}
                    type="text"
                    className="text-btn2"
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Space>
        </Space>
      </Form>
    </>
  );
}

export default ArticleForm;
