import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Col,
  Form,
  Input,
  Row,
  Space
} from 'antd';
import React, { useEffect, useMemo } from 'react';
import * as urlSlug from 'url-slug';
import { useApp } from '../../../AppContext';
import {
  ASSET_CATEGORY,
  MAX_LENGTHS,
  MODULES,
  PAGE_TYPES,
  ROUTES,
  STATUS_OPTIONS,
  STATUS_TYPES,
  UNPUBLISHED_STATUS,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import PageHeader from '../../../components/PageHeader';
import useCheckPermission from '../../../hooks/useCheckPermission';
import SelectAsset from '../../assets/components/SelectAsset';
import { DEFAULT_CONFIG_KEYS } from '../../workspaces/components';
import { ColorPicker, SlugInput } from '../topics/components/FormInputs';
import AddEditCollectionItems from './components/AddEditCollectionItems';
import { CREATE_COLLECTION, UPDATE_COLLECTION } from './graphql/Mutations';
import { GET_COLLECTION } from './graphql/Queries';

const COLOR_KEYS = {
  neutral800: 'neutral800',
  blue2: 'blue2',
  moss2: 'moss2'
};

const initialValues = {
  title: '',
  description: '',
  slug: '/',
  status: STATUS_TYPES.DRAFT,
  thumbnail: {
    id: '',
    url: ''
  },
  primaryColor: COLOR_KEYS.neutral800,
  videoData: [],
  metaHeader: '',
  metaFooter: '',
  items: [{ type: 'VIDEO', itemId: null, sequence: null }]
};

const AddEditCollection = ({ history, match: { params } }) => {
  const { state } = useApp();
  const workspaceColors = state?.workspaceConfig?.find(
    ({ key }) => key === DEFAULT_CONFIG_KEYS.COLORS
  )?.value;
  const [form] = Form.useForm();
  const { collectionId } = params;
  const parentCollectionSlug = Form.useWatch(['slug'], form);
  const isAssetEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);
  const isAssetViewAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isEdit = !!collectionId;

  const colors = useMemo(
    () => [
      {
        key: COLOR_KEYS.neutral800,
        color: workspaceColors?.[COLOR_KEYS.neutral800]
      },
      {
        key: COLOR_KEYS.blue2,
        color: workspaceColors?.[COLOR_KEYS.blue2]
      },
      {
        key: COLOR_KEYS.moss2,
        color: workspaceColors?.[COLOR_KEYS.moss2]
      }
    ],
    [workspaceColors]
  );

  const [fetchCollectionDetails, { loading: fetchingDetails }] = useLazyQuery(
    GET_COLLECTION,
    {
      fetchPolicy: 'network-only'
    }
  );

  const [addUpdateCollection, { loading }] = useMutation(
    isEdit ? UPDATE_COLLECTION : CREATE_COLLECTION
  );

  const getItemData = (data, type) => {
    switch (type) {
      case PAGE_TYPES.FORM:
        return {
          label: data?.title,
          value: data?.formId
        };
      case PAGE_TYPES.ARTICLE:
        return {
          id: data?.articleId,
          title: data?.title,
          url: data?.imageThumbnail?.url ?? ''
        };
      case PAGE_TYPES.PODCAST:
        return {
          id: data?.podcastId,
          title: data?.title,
          url: data?.imageThumbnail?.url ?? ''
        };
      default:
        return {
          id: data?.videoId,
          title: data?.title,
          url: data?.imageThumbnail?.url ?? ''
        };
    }
  };

  useEffect(() => {
    if (isEdit && !!collectionId) {
      fetchCollectionDetails({
        variables: {
          id: collectionId,
          filter: {
            pagination: false
          }
        }
      })
        .then(({ data }) => {
          const collection = data?.collectionAdmin;
          const collectionItems = data?.collectionItemsAdmin?.collectionItems;
          if (collection) {
            const {
              title,
              description,
              slug,
              primaryColor,
              thumbnail,
              status,
              metaHeader,
              metaFooter
            } = collection;
            form.setFieldsValue({
              title,
              description,
              slug: slug.startsWith('/') ? slug : `/${slug}`,
              status,
              primaryColor,
              thumbnail: thumbnail ?? {
                id: '',
                url: ''
              },
              metaHeader: metaHeader ?? '',
              metaFooter: metaFooter ?? '',
              items: collectionItems?.map((item) => {
                return {
                  type: item?.type,
                  sequence: item?.sequence ?? null,
                  itemId: getItemData(item?.itemData, item?.type)
                };
              })
            });
          }
        })
        .catch();
    }
  }, [collectionId, isEdit, form]);

  const handleSubmit = ({
    slug,
    thumbnail,
    items: itemsData,
    ...restValues
  }) => {
    const payload = {
      ...restValues,
      slug: slug?.startsWith('/') ? slug?.substring(1) : slug,
      thumbnailId: thumbnail?.id || null
    };

    addUpdateCollection({
      variables: {
        data: payload,
        ...(isEdit && {
          id: collectionId
        })
      }
    })
      .then(async (res) => {
        if (res?.data) {
          if (isEdit) {
            history.push(ROUTES?.COLLECTIONS);
          } else {
            history.push(
              `${ROUTES?.COLLECTIONS}/${res?.data?.createCollection?.collection?.id}/edit`
            );
          }
        }
      })
      .catch();
  };

  const handleCancel = () => {
    history.push(ROUTES?.COLLECTIONS);
  };

  const handleTitleChange = (e) => {
    form.setFieldValue('slug', `/${urlSlug.convert(e.target.value)}`);
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  return (
    <>
      <PageHeader menu={MODULES?.LABELS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            className="add-edit-form"
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={initialValues}
            disabled={isViewOnly || fetchingDetails}
          >
            <Row gutter={[10, 0]}>
              <Col md={24} lg={8}>
                <Form.Item
                  label="Title"
                  name="title"
                  required
                  rules={[
                    formValidatorRules?.required('Please enter title!'),
                    formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
                  ]}
                >
                  <Input
                    placeholder="Enter title"
                    onChange={handleTitleChange}
                  />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea placeholder="Enter description" />
                </Form.Item>
                <Form.Item
                  label="Slug"
                  name="slug"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter slug!'
                    },
                    formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
                  ]}
                >
                  <SlugInput />
                </Form.Item>

                <Form.Item label="Status" name="status">
                  <AntdSelect
                    options={[...STATUS_OPTIONS, UNPUBLISHED_STATUS].map(
                      ({ name, value }) => ({
                        label: name,
                        value
                      })
                    )}
                    placeholder="Select status"
                  />
                </Form.Item>

                <Form.Item label="Background Color" name="primaryColor">
                  <ColorPicker data={colors} />
                </Form.Item>
                <Form.Item label="Main Image" name="thumbnail">
                  <SelectAsset
                    disabled={isViewOnly}
                    modalTitle="Select Image"
                    categoryKey={ASSET_CATEGORY.IMAGE}
                    btnText="Image"
                    dataSelector={({ id, url }) => ({
                      id,
                      url
                    })}
                    isAssetEditAllowed={isAssetEditAllowed}
                    isAssetViewAllowed={isAssetViewAllowed}
                  />
                </Form.Item>
                <Form.Item
                  name="metaHeader"
                  label="Meta Header"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea rows={5} placeholder="Enter meta header" />
                </Form.Item>

                <Form.Item
                  name="metaFooter"
                  label="Meta Footer"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea rows={5} placeholder="Enter meta footer" />
                </Form.Item>
              </Col>
              <Col md={24} lg={16}>
                {isEdit && (
                  <AddEditCollectionItems
                    collectionId={collectionId}
                    isEdit={isEdit}
                    parentCollectionSlug={parentCollectionSlug}
                  />
                )}
              </Col>
            </Row>
            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    disabled={loading || fetchingDetails}
                    loading={loading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}
                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditCollection;
