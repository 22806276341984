import { useLazyQuery } from '@apollo/client';
import { find, flattenDeep, isEmpty, map } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { toast } from '../../apollo';
import { ROUTES } from '../../common/constants';
import PageHeader from '../../components/PageHeader';
import { GET_LOOPS } from './graphql/Queries';

const CALENDAR_VIEWS = {
  MONTH: 'month',
  WEEK: 'week',
  DAY: 'day',
  TODAY: 'TODAY',
  NEXT: 'NEXT',
  PREVIOUS: 'PREV'
};

const Loop = ({ history }) => {
  const localizer = momentLocalizer(moment);
  const [loops, setLoops] = useState([]);
  const [dataRange, setDataRange] = useState();
  const [viewType, setViewType] = useState();
  const [date, setDate] = useState({
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month')
  });
  const [fetchLoops] = useLazyQuery(GET_LOOPS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const finalLoops = map(res?.loopsAdmin?.loops, (loop) => [
        {
          start: new Date(
            moment(loop?.startDate).utc().tz(moment.tz.guess(), true).format()
          ),
          end: new Date(
            moment(loop?.endDate).utc().tz(moment.tz.guess(), true).format()
          ),
          title: `Ads: ${loop?.contentCount?.adCount}`,
          id: loop?.id,
          allDay: true
        },
        {
          start: new Date(
            moment(loop?.startDate).utc().tz(moment.tz.guess(), true).format()
          ),
          end: new Date(
            moment(loop?.endDate).utc().tz(moment.tz.guess(), true).format()
          ),
          title: `Videos: ${loop?.contentCount?.videoCount}`,
          id: loop?.id,
          allDay: true
        },
        {
          start: new Date(
            moment(loop?.startDate).utc().tz(moment.tz.guess(), true).format()
          ),
          end: new Date(
            moment(loop?.endDate).utc().tz(moment.tz.guess(), true).format()
          ),
          title: `Podcasts: ${loop?.contentCount?.podcastCount}`,
          id: loop?.id,
          allDay: true
        },
        {
          start: new Date(
            moment(loop?.startDate).utc().tz(moment.tz.guess(), true).format()
          ),
          end: new Date(
            moment(loop?.endDate).utc().tz(moment.tz.guess(), true).format()
          ),
          title: `Articles: ${loop?.contentCount?.articleCount}`,
          id: loop?.id,
          allDay: true
        }
      ]);
      setLoops(flattenDeep(finalLoops));
    },
    onError() {}
  });

  useEffect(() => {
    if (!isEmpty(date)) {
      fetchLoops({
        variables: {
          filter: date
        }
      });
    }
  }, [date]);

  useMemo(() => {
    if (viewType && dataRange) {
      if (viewType === CALENDAR_VIEWS.WEEK) {
        setDate({
          startDate: moment(dataRange?.[0]).startOf('day'),
          endDate: moment(dataRange?.[6]).endOf('day')
        });
      }
      if (viewType === CALENDAR_VIEWS.DAY) {
        setDate({
          startDate: moment(dataRange?.[0]).startOf('day'),
          endDate: moment(dataRange?.[0]).endOf('day')
        });
      }
      if (viewType === CALENDAR_VIEWS.MONTH) {
        const getMonth = moment(dataRange?.start)?.add(7, 'days');
        setDate({
          startDate: moment(getMonth).startOf('month'),
          endDate: moment(getMonth).endOf('month')
        });
      }
    }
  }, [dataRange, viewType]);

  const handleRangeChange = (range, view) => {
    setDataRange(range);
    if (view) {
      setViewType(view);
    }
  };

  const handleNavigate = (getDate, view, action) => {
    const isEventPresent = find(loops, (item) => {
      return (
        moment(item.start).format('YYYY-MM-DD') ===
        moment(getDate).format('YYYY-MM-DD')
      );
    });
    if (view && action === 'DATE') {
      if (isEventPresent) {
        history.push({
          pathname: `${ROUTES?.LOOP}/${isEventPresent?.id}/edit`
        });
        return;
      }
      history.push({
        pathname: `${ROUTES?.LOOP}/add`,
        date: getDate
      });
    } else if (view) {
      setViewType(view);
    }
  };

  const slotPropGetter = (day) => {
    const time = moment(day);
    const isSameHour = time.hour() === moment().hour();
    if (
      (time.isBefore(moment(), 'day') ||
        time.hour() < moment().hour() ||
        (isSameHour && moment().minute() >= time.minute())) &&
      !time.isAfter(moment(), 'day')
    ) {
      return { className: 'past-dates' };
    }
  };

  const dayPropGetter = useCallback(
    (day) => ({
      ...(moment(day).isBefore(moment(), 'day') && {
        className: 'past-dates'
      })
    }),
    []
  );

  return (
    <>
      <PageHeader />
      <div className="calendar-div">
        <Calendar
          events={loops}
          localizer={localizer}
          views={['week', 'month', 'day']}
          onNavigate={handleNavigate}
          onRangeChange={handleRangeChange}
          selectable
          showAllEvents
          onSelectSlot={(e) => {
            const start = moment(e.start);
            const end = moment(e.end);
            if (
              !end.isAfter(moment(), 'day') &&
              (end.isBefore(moment(), 'day') ||
                start.hour() < moment().hour() ||
                (start.hour() === moment().hour() &&
                  moment().minute() >= end.minute()))
            ) {
              toast({
                message: 'Please select future date!',
                type: 'error'
              });
            } else {
              const isEventPresent = find(loops, (item) => {
                return (
                  moment(item.start).format('YYYY-MM-DD') ===
                  moment(start).format('YYYY-MM-DD')
                );
              });
              if (isEventPresent) {
                history.push({
                  pathname: `${ROUTES?.LOOP}/${isEventPresent?.id}/edit`
                });
                return;
              }
              history.push({
                pathname: `${ROUTES?.LOOP}/add`,
                date: e?.slots?.[0]
              });
            }
          }}
          onSelectEvent={(event) => {
            history.push({
              pathname: `${ROUTES?.LOOP}/${event?.id}/edit`
            });
          }}
          dayPropGetter={dayPropGetter}
          slotPropGetter={slotPropGetter}
        />
      </div>
    </>
  );
};

export default Loop;
