import { gql } from '@apollo/client';

export const GET_PAGES = gql`
  query pagesAdmin($filter: PagesAdminFilter, $sort: PagesSort) {
    pagesAdmin(filter: $filter, sort: $sort) {
      count
      pages {
        id
        slug
        title
        description
        status
        type
        isDefault
        allowDelete
        createdBy {
          id
          email
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PAGE = gql`
  query pageAdmin($where: PageUniqueInput!) {
    pageAdmin(where: $where) {
      id
      slug
      title
      description
      status
      type
      isDefault
      metaHeader
      metaFooter
      generationType
      createdBy {
        id
        email
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_PAGE_MODULE_TYPES = gql`
  query pageModuleTypes {
    pageModuleTypes {
      category
      key
      name
    }
  }
`;

export const GET_PERMISSIONS = gql`
  query permissions {
    permissions {
      count
      permissions {
        key
        name
      }
    }
  }
`;

export const GET_AUTOGENERATE_BY_TOPIC = gql`
  query topicsAdmin($filter: TopicsFilter) {
    topicsAdmin(filter: $filter) {
      count
      topics {
        id
        name
      }
    }
  }
`;

export const GET_AUTOGENERATE_BY_TAGS = gql`
  query tagsAdmin($filter: TagsFilter) {
    tagsAdmin(filter: $filter) {
      count
      tags {
        id
        name
      }
    }
  }
`;

export const GET_AUTOGENERATE_BY_CONTRIBUTORS = gql`
  query contributorsAdmin(
    $filter: ContributorsFilter
    $where: ContributorsWhereInput
  ) {
    contributorsAdmin(filter: $filter, where: $where) {
      count
      contributors {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_AUTOGENERATE_BY_COLLECTIONS = gql`
  query collectionsAdmin($filter: CollectionsFilter) {
    collectionsAdmin(filter: $filter) {
      count
      collections {
        id
        title
        description
      }
    }
  }
`;

export const GET_AUTO_GENERATED_BY_SOURCE = gql`
  query sources($where: SourcesWhereInput, $filter: SourcesFilter) {
    sourcesAdmin(where: $where, filter: $filter) {
      sources {
        id
        name
      }
      count
    }
  }
`;

export const GET_AUTO_GENERATED_BY_COMMUNITIES = gql`
  query communitiesAdmin($filter: CommunitiesFilter) {
    communitiesAdmin(filter: $filter) {
      count
      communities {
        id
        title
        description
      }
    }
  }
`;
const video = `
    video {
      id
      title
      imageThumbnail {
        url
      }
    }
`;
const videos = `
  videos {
    order
    id
    title
    imageThumbnail {
      url
    }
  }
`;

const getVideoConfig = (configKey, key, isMultiVideo = true) => `
  fragment ${configKey} on ${key} {
    ${isMultiVideo ? videos : video}
    videoAutoGenerateByType: autoGenerateByType
    videoAutoGenerateSortBy: autoGenerateSortBy
    autoGenerateByData {
      ... on Contributor {
        contributorId: id
        contributorFirstName: firstName,
        contributorLastName: lastName
      }
      ... on Topic {
        topicId: id
        topicName: name
      }
      ... on Tag {
        tagId: id
        tagName: name
      }
      ... on Collection {
        collectionId: id
        collectionTitle: title
      }
      ... on Source {
        sourceId: id,
        sourceName: name
      }
    }
  }
`;

const VIDEO_CONTINUE_WATCHING_CAROUSEL_FRAGMENT = gql`
  fragment videoContinueWatchingCarouselData on VideoContinueWatchingCarouselData {
    settings {
      title
      description
      videoTimestamp
      videoDate
      videoSpeaker
      videoTopic
      videoReactions
    }
  }
`;
const VIDEO_WATCH_LATER_CAROUSEL_FRAGMENT = gql`
  fragment videoWatchLaterCarouselData on VideoWatchLaterCarouselData {
    settings {
      title
      description
      videoTimestamp
      videoDate
      videoSpeaker
      videoTopic
      videoReactions
    }
  }
`;

const VIDEO_CAROUSEL_CONFIG_FRAGMENT = gql(
  getVideoConfig('carouselConfig', 'VideoCarouselModuleConfigData')
);
const VIDEO_CAROUSEL_FRAGMENT = gql`
  ${VIDEO_CAROUSEL_CONFIG_FRAGMENT}
  fragment videoCarouselData on VideoCarouselData {
    settings {
      title
      description
      videoTimestamp
      videoDate
      videoSpeaker
      videoTopic
      videoOrder
      videoProgressBar
      videoReactions
      viewAll
    }
    config {
      ...carouselConfig
      autoGenerateLimit
      method
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const VIDEO_LIST_CONFIG_FRAGMENT = gql(
  getVideoConfig('listConfig', 'VideoListModuleConfigData')
);
const VIDEO_LIST_FRAGMENT = gql`
  ${VIDEO_LIST_CONFIG_FRAGMENT}
  fragment videoListFragment on VideoListData {
    settings {
      title
      description
      videoTimestamp
      videoDate
      videoSpeaker
      videoTopic
      videoOrder
      videoProgressBar
      videoReactions
      viewAll
    }
    config {
      ...listConfig
      autoGenerateLimit
      method
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const VIDEO_GRID_CONFIG_FRAGMENT = gql(
  getVideoConfig('gridConfig', 'VideoGridModuleConfigData')
);
const VIDEO_GRID_FRAGMENT = gql`
  ${VIDEO_GRID_CONFIG_FRAGMENT}
  fragment videoGridFragment on VideoGridData {
    settings {
      title
      description
      videoTimestamp
      videoDate
      videoSpeaker
      videoProgressBar
      videoReactions
      viewAll
    }
    config {
      ...gridConfig
      autoGenerateLimit
      method
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;
const FEATURED_VIDEO_CONFIG_FRAGMENT = gql(
  getVideoConfig('featureVideoConfig', 'FeaturedVideoModuleConfigData', false)
);
const FEATURED_VIDEO_FRAGMENT = gql`
  ${FEATURED_VIDEO_CONFIG_FRAGMENT}
  fragment featuredVideoFragment on FeaturedVideoData {
    settings {
      videoDescription
      videoTimestamp
      videoDate
      videoSpeaker
      videoTopic
      videoReactions
    }
    config {
      ...featureVideoConfig
      method
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const FEATURED_VIDEO_SLIDER_CONFIG_FRAGMENT = gql(
  getVideoConfig(
    'featureVideoSliderConfig',
    'FeaturedVideoSliderModuleConfigData'
  )
);
const FEATURED_VIDEO_SLIDER_FRAGMENT = gql`
  ${FEATURED_VIDEO_SLIDER_CONFIG_FRAGMENT}
  fragment featuredVideoSlicerFragment on FeaturedVideoSliderData {
    settings {
      videoDescription
      videoTimestamp
      videoDate
      videoSpeaker
      videoTopic
      videoReactions
      videoOrder
      videoCTA
    }
    config {
      ...featureVideoSliderConfig
      autoGenerateLimit
      method
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const VIDEO_PLAYER_FRAGMENT = gql`
  fragment videoPlayerFragment on VideoPlayerData {
    settings {
      videoDescription
      videoTimestamp
      videoDate
      videoSpeaker
      videoTopic
      videoReactions
    }
    config {
      method
      video {
        id
        title
        imageThumbnail {
          url
        }
      }
    }
  }
`;

const podcast = `
  id
  title
  imageThumbnail {
    url
  }
`;

const featurePodcast = `
  podcast {
    order
    id
    title
    imageThumbnail {
      url
    }
  }
`;

const podcasts = `
  podcasts {
    order
    ${podcast}
  }
`;

const getPodcastConfig = (configKey, key, isMultiPodcast = true) => `
  fragment ${configKey} on ${key} {
    ${isMultiPodcast ? podcasts : podcast}
    podcastAutoGenerateByType: autoGenerateByType
    podcastAutoGenerateSortBy: autoGenerateSortBy
    autoGenerateLimit
    autoGenerateByData {
      ... on Contributor {
        contributorId: id
        contributorFirstName: firstName,
        contributorLastName: lastName
      }
      ... on Topic {
        topicId: id
        topicName: name
      }
      ... on Tag {
        tagId: id
        tagName: name
      }
      ... on Collection {
        collectionId: id
        collectionTitle: title
      }
      ... on Source {
        sourceId: id,
        sourceName: name
      }
    }
  }
`;

const getFeaturePodcastConfig = (configKey, key) => `
  fragment ${configKey} on ${key} {
    ${featurePodcast}
    podcastAutoGenerateByType: autoGenerateByType
    podcastAutoGenerateSortBy: autoGenerateSortBy
    autoGenerateByData {
      ... on Contributor {
        contributorId: id
        contributorFirstName: firstName,
        contributorLastName: lastName
      }
      ... on Topic {
        topicId: id
        topicName: name
      }
      ... on Tag {
        tagId: id
        tagName: name
      }
      ... on Collection {
        collectionId: id
        collectionTitle: title
      }
      ... on Source {
        sourceId: id,
        sourceName: name
      }
    }
  }
`;

const PODCAST_CONTINUE_LISTENING_CAROUSEL_FRAGMENT = gql`
  fragment podcastContinueListeningCarouselData on PodcastContinueListeningCarouselData {
    settings {
      title
      description
      viewAll
      podcastTimestamp
      podcastDate
      podcastSpeaker
      podcastTopic
    }
  }
`;

const PODCAST_LISTEN_LATER_CAROUSEL_FRAGMENT = gql`
  fragment podcastListenLaterCarouselData on PodcastListenLaterCarouselData {
    settings {
      title
      description
      viewAll
      podcastTimestamp
      podcastDate
      podcastSpeaker
      podcastTopic
    }
  }
`;

const PODCAST_CAROUSEL_CONFIG_FRAGMENT = gql(
  getPodcastConfig('podcastCarouselConfig', 'PodcastCarouselModuleConfigData')
);
const PODCAST_CAROUSEL_FRAGMENT = gql`
  ${PODCAST_CAROUSEL_CONFIG_FRAGMENT}
  fragment podcastCarouselData on PodcastCarouselData {
    settings {
      title
      description
      podcastTimestamp
      podcastDate
      podcastSpeaker
      podcastTopic
      podcastProgressBar
      imageThumbnail
      podcastReactions
      podcastOrder
      viewAll
    }
    config {
      ...podcastCarouselConfig
      method
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const PODCAST_LIST_CONFIG_FRAGMENT = gql(
  getPodcastConfig('podcastListConfig', 'PodcastListModuleConfigData')
);
const PODCAST_LIST_FRAGMENT = gql`
  ${PODCAST_LIST_CONFIG_FRAGMENT}
  fragment podcastListFragment on PodcastListData {
    settings {
      title
      description
      podcastTimestamp
      podcastDate
      podcastSpeaker
      podcastTopic
      podcastOrder
      podcastProgressBar
      viewAll
      imageThumbnail
      podcastReactions
    }
    config {
      ...podcastListConfig
      method
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const PODCAST_GRID_CONFIG_FRAGMENT = gql(
  getPodcastConfig('podcastGridConfig', 'PodcastGridModuleConfigData')
);
const PODCAST_GRID_FRAGMENT = gql`
  ${PODCAST_GRID_CONFIG_FRAGMENT}
  fragment podcastGridFragment on PodcastGridData {
    settings {
      title
      description
      imageThumbnail
      podcastDate
      podcastSpeaker
      podcastTimestamp
      podcastProgressBar
      podcastReactions
      viewAll
    }
    config {
      ...podcastGridConfig
      method
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const FEATURED_PODCAST_CONFIG_FRAGMENT = gql(
  getFeaturePodcastConfig(
    'featurePodcastConfig',
    'FeaturedPodcastModuleConfigData',
    false
  )
);

const FEATURED_PODCAST_FRAGMENT = gql`
  ${FEATURED_PODCAST_CONFIG_FRAGMENT}
  fragment featuredPodcastFragment on FeaturedPodcastData {
    settings {
      podcastDescription
      podcastTimestamp
      podcastDate
      podcastSpeaker
      podcastTopic
      imageThumbnail
      podcastReactions
    }
    config {
      ...featurePodcastConfig
      method
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const FEATURED_PODCAST_SLIDER_CONFIG_FRAGMENT = gql(
  getPodcastConfig(
    'featurePodcastSliderConfig',
    'FeaturedPodcastSliderModuleConfigData'
  )
);

const FEATURED_PODCAST_SLIDER_FRAGMENT = gql`
  ${FEATURED_PODCAST_SLIDER_CONFIG_FRAGMENT}
  fragment featuredPodcastSliderFragment on FeaturedPodcastSliderData {
    settings {
      podcastDescription
      podcastTimestamp
      podcastDate
      podcastSpeaker
      podcastTopic
      imageThumbnail
      podcastReactions
      podcastCTA
      podcastOrder
    }
    config {
      ...featurePodcastSliderConfig
      method
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const PODCAST_PLAYER_FRAGMENT = gql`
  fragment podcastPlayerFragment on PodcastPlayerData {
    settings {
      podcastDate
    }
    config {
      method
      podcast {
        id
        title
        imageThumbnail {
          url
        }
      }
    }
  }
`;

const collection = `
  collection {
    id
    title
    thumbnail {
      url
    }
  }
`;
const collections = `
  collections {
    order
    id
    title
    thumbnail {
      url
    }
  }
`;
const getCollectionConfig = (configKey, key, isMultiple = true) => `
 fragment ${configKey} on ${key} {
    aspectRatio
    collectionAutoGenerateSortBy: autoGenerateSortBy
    ${isMultiple ? collections : collection}
  }
`;

const COLLECTION_CAROUSEL_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'collectionCarouselConfigFragment',
    'CollectionCarouselModuleConfigData'
  )
);

const COLLECTION_CAROUSEL_FRAGMENT = gql`
  ${COLLECTION_CAROUSEL_CONFIG_FRAGMENT}
  fragment collectionCarouselFragment on CollectionCarouselData {
    settings {
      title
      description
      numberOfItems
    }
    config {
      ...collectionCarouselConfigFragment
      autoGenerateLimit
      method
    }
  }
`;

const COLLECTION_GRID_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'collectionGridConfigFragment',
    'CollectionGridModuleConfigData'
  )
);

const COLLECTION_GRID_FRAGMENT = gql`
  ${COLLECTION_GRID_CONFIG_FRAGMENT}
  fragment collectionGridFragment on CollectionGridData {
    settings {
      title
      description
      numberOfItems
    }
    config {
      ...collectionGridConfigFragment
      autoGenerateLimit
      method
    }
  }
`;

const COLLECTION_LIST_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'collectionListConfigFragment',
    'CollectionListModuleConfigData'
  )
);
const COLLECTION_LIST_FRAGMENT = gql`
  ${COLLECTION_LIST_CONFIG_FRAGMENT}
  fragment collectionListFragment on CollectionListData {
    settings {
      title
      description
      numberOfItems
    }
    config {
      ...collectionListConfigFragment
      autoGenerateLimit
      method
    }
  }
`;

const FEATURED_COLLECTION_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'featuredCollectionConfigFragment',
    'FeaturedCollectionModuleConfigData',
    false
  )
);
const FEATURED_COLLECTION_FRAGMENT = gql`
  ${FEATURED_COLLECTION_CONFIG_FRAGMENT}
  fragment featuredCollectionFragment on FeaturedCollectionData {
    settings {
      title
      description
      numberOfItems
    }
    config {
      ...featuredCollectionConfigFragment
      method
    }
  }
`;

const FEATURED_COLLECTION_SLIDER_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'featuredCollectionSliderConfigFragment',
    'FeaturedCollectionSliderModuleConfigData'
  )
);
const FEATURED_COLLECTION_SLIDER_FRAGMENT = gql`
  ${FEATURED_COLLECTION_SLIDER_CONFIG_FRAGMENT}
  fragment featuredCollectionSliderFragment on FeaturedCollectionSliderData {
    settings {
      collectionTitle
      collectionDescription
      collectionImage
    }
    config {
      ...featuredCollectionSliderConfigFragment
      autoGenerateLimit
      method
    }
  }
`;

const getCollectionDetailConfig = (configKey, key, isMultiple = true) => `
 fragment ${configKey} on ${key} {
    method
    collectionAutoGenerateSortBy: autoGenerateSortBy
    ${isMultiple ? collections : collection}
  }
`;

const COLLECTION_DETAIL_LIST_CONFIG_FRAGMENT = gql(
  getCollectionDetailConfig(
    'collectionDetailListConfigFragment',
    'CollectionDetailListModuleConfigData',
    false
  )
);

const COLLECTION_DETAIL_LIST_FRAGMENT = gql`
  ${COLLECTION_DETAIL_LIST_CONFIG_FRAGMENT}
  fragment collectionDetailListFragment on CollectionDetailListData {
    settings {
      imageThumbnail
      viewAll
      viewProgressBar
    }
    config {
      ...collectionDetailListConfigFragment
      subCollection {
        id
        title
      }
    }
  }
`;

const COLLECTION_DETAIL_GRID_CONFIG_FRAGMENT = gql(
  getCollectionDetailConfig(
    'collectionDetailGridConfigFragment',
    'CollectionDetailGridModuleConfigData',
    false
  )
);

const COLLECTION_DETAIL_GRID_FRAGMENT = gql`
  ${COLLECTION_DETAIL_GRID_CONFIG_FRAGMENT}
  fragment collectionDetailGridFragment on CollectionDetailGridData {
    settings {
      imageThumbnail
      viewAll
      viewProgressBar
    }
    config {
      ...collectionDetailGridConfigFragment
      subCollection {
        id
        title
      }
    }
  }
`;

const contributor = `
  contributor {
    id
    firstName
    lastName
    thumbnail {
      url
    }
    image {
      url
    }
    primaryColor
  }
`;
const contributors = `
  contributors {
    order
    id
    firstName
    lastName
    thumbnail {
      url
    }
    image {
      url
    }
    primaryColor
  }
`;
const getContributorConfig = (configKey, key, isMultiple = true) => `
 fragment ${configKey} on ${key} {
    contributorAutoGenerateSortBy: autoGenerateSortBy
    ${isMultiple ? contributors : contributor}
  }
`;

const CONTRIBUTOR_CAROUSEL_CONFIG_FRAGMENT = gql(
  getContributorConfig(
    'contributorCarouselConfigFragment',
    'ContributorCarouselModuleConfigData'
  )
);
const CONTRIBUTOR_CAROUSEL_FRAGMENT = gql`
  ${CONTRIBUTOR_CAROUSEL_CONFIG_FRAGMENT}
  fragment contributorCarouselFragment on ContributorCarouselData {
    settings {
      title
      description
      viewAll
      numberOfVideos
      numberOfPodcasts
      numberOfArticles
    }
    config {
      ...contributorCarouselConfigFragment
      autoGenerateLimit
      method
    }
  }
`;

const CONTRIBUTOR_LIST_CONFIG_FRAGMENT = gql(
  getContributorConfig(
    'contributorListConfigFragment',
    'ContributorListModuleConfigData'
  )
);

const CONTRIBUTOR_LIST_FRAGMENT = gql`
  ${CONTRIBUTOR_LIST_CONFIG_FRAGMENT}
  fragment contributorListFragment on ContributorListData {
    settings {
      title
      description
      separator
      contributorDescription
      numberOfVideos
      numberOfPodcasts
      numberOfArticles
    }
    config {
      ...contributorListConfigFragment
      autoGenerateLimit
      method
    }
  }
`;

const CONTRIBUTOR_GRID_CONFIG_FRAGMENT = gql(
  getContributorConfig(
    'contributorGridConfigFragment',
    'ContributorGridModuleConfigData'
  )
);
const CONTRIBUTOR_GRID_FRAGMENT = gql`
  ${CONTRIBUTOR_GRID_CONFIG_FRAGMENT}
  fragment contributorGridFragment on ContributorGridData {
    settings {
      title
      description
      numberOfVideos
      numberOfPodcasts
      numberOfArticles
    }
    config {
      ...contributorGridConfigFragment
      autoGenerateLimit
      method
    }
  }
`;

const FEATURED_CONTRIBUTOR_CONFIG_FRAGMENT = gql(
  getContributorConfig(
    'featuredContributorGridConfigFragment',
    'FeaturedContributorModuleConfigData',
    false
  )
);
const FEATURED_CONTRIBUTOR_FRAGMENT = gql`
  ${FEATURED_CONTRIBUTOR_CONFIG_FRAGMENT}
  fragment featuredContributorGridFragment on FeaturedContributorData {
    settings {
      contributorName
      numberOfVideos
      numberOfPodcasts
      contributorImage
      numberOfArticles
    }
    config {
      ...featuredContributorGridConfigFragment
      method
    }
  }
`;

const tag = `
  tag {
    id
    name
  }
`;
const tags = `
  tags {
    order
    id
    name
  }
`;
const getTagConfig = (configKey, key, isMultiple = true) => `
 fragment ${configKey} on ${key} {
    tagAutoGenerateSortBy: autoGenerateSortBy
    ${isMultiple ? tags : tag}
  }
`;

const TAG_CAROUSEL_CONFIG_FRAGMENT = gql(
  getTagConfig('tagCarouselConfigFragment', 'TagCarouselModuleConfigData')
);
const TAG_CAROUSEL_FRAGMENT = gql`
  ${TAG_CAROUSEL_CONFIG_FRAGMENT}
  fragment tagCarouselFragment on TagCarouselData {
    settings {
      title
      description
    }
    config {
      ...tagCarouselConfigFragment
      autoGenerateLimit
      method
    }
  }
`;

const TAG_CLOUD_CONFIG_FRAGMENT = gql(
  getTagConfig('tagCloudConfigFragment', 'TagCloudModuleConfigData')
);
const TAG_CLOUD_FRAGMENT = gql`
  ${TAG_CLOUD_CONFIG_FRAGMENT}
  fragment tagCloudFragment on TagCloudData {
    settings {
      title
      description
    }
    config {
      ...tagCloudConfigFragment
      autoGenerateLimit
      method
    }
  }
`;

const FEATURED_TAG_CONFIG_FRAGMENT = gql(
  getTagConfig(
    'featuredTagConfigFragment',
    'FeaturedTagModuleConfigData',
    false
  )
);
const FEATURED_TAG_FRAGMENT = gql`
  ${FEATURED_TAG_CONFIG_FRAGMENT}
  fragment featuredTagFragment on FeaturedTagData {
    config {
      ...featuredTagConfigFragment
      method
    }
  }
`;

const topic = `
  topic {
    id
    name
  }
`;
const topics = `
  topics {
    order
    id
    name
  }
`;
const getTopicsConfig = (configKey, key, isMultiple = true) => `
 fragment ${configKey} on ${key} {
    topicAutoGenerateSortBy: autoGenerateSortBy
    ${isMultiple ? topics : topic}
  }
`;

const TOPIC_CAROUSEL_CONFIG_FRAGMENT = gql(
  getTopicsConfig(
    'topicCarouselConfigFragment',
    'TopicCarouselModuleConfigData'
  )
);
const TOPIC_CAROUSEL_FRAGMENT = gql`
  ${TOPIC_CAROUSEL_CONFIG_FRAGMENT}
  fragment topicCarouselFragment on TopicCarouselData {
    settings {
      title
      description
    }
    config {
      ...topicCarouselConfigFragment
      autoGenerateLimit
      method
    }
  }
`;

const TOPIC_LIST_CONFIG_FRAGMENT = gql(
  getTopicsConfig('topicListConfigFragment', 'TopicListModuleConfigData')
);
const TOPIC_LIST_FRAGMENT = gql`
  ${TOPIC_LIST_CONFIG_FRAGMENT}
  fragment topicListFragment on TopicListData {
    settings {
      title
      description
    }
    config {
      ...topicListConfigFragment
      autoGenerateLimit
      method
    }
  }
`;

const TOPIC_GRID_CONFIG_FRAGMENT = gql(
  getTopicsConfig('topicGridConfigFragment', 'TopicGridModuleConfigData')
);
const TOPIC_GRID_FRAGMENT = gql`
  ${TOPIC_GRID_CONFIG_FRAGMENT}
  fragment topicGridFragment on TopicGridData {
    settings {
      title
      description
    }
    config {
      ...topicGridConfigFragment
      autoGenerateLimit
      method
    }
  }
`;

const FEATURED_TOPIC_CONFIG_FRAGMENT = gql(
  getTopicsConfig(
    'featuredTopicConfigFragment',
    'FeaturedTopicModuleConfigData',
    false
  )
);
const FEATURED_TOPIC_FRAGMENT = gql`
  ${FEATURED_TOPIC_CONFIG_FRAGMENT}
  fragment featuredTopicFragment on FeaturedTopicData {
    config {
      ...featuredTopicConfigFragment
      method
    }
  }
`;

const CTA_BLOCK_FRAGMENT = gql`
  fragment ctaBlockFragment on CTABlockData {
    settings {
      image
      description
      secondaryAction
      eventDetails
    }
    config {
      asset {
        id
        url
      }
      theme
      eventTime
      eventDate
      eventLocation
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
    }
  }
`;

const CTA_BANNER_WITH_TEXT_FRAGMENT = gql`
  fragment ctaBannerWithTextFragment on CTABannerWithTextData {
    settings {
      title
      image
      description
    }
    config {
      asset {
        id
        url
      }
      theme
      primaryAction {
        title
        type
        url
        internalPageType
      }
    }
  }
`;

const HERO_FRAGMENT = gql`
  fragment heroFragment on HeroData {
    settings {
      description
      primaryAction
      secondaryAction
      textFields
    }
    config {
      asset {
        id
        url
        categoryKey
        serviceImageThumbnail
        serviceVideoThumbnail
      }
      eventTime
      layout
      eventDate
      eventLocation
      alignment
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
    }
  }
`;

const INFORMATION_FRAGMENT = gql`
  fragment informationFragment on InformationData {
    settings {
      description
      primaryAction
      secondaryAction
    }
    config {
      asset {
        id
        url
      }
      theme
      imageOrientation
      imageType
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
    }
  }
`;

const CTA_BANNER_FRAGMENT = gql`
  fragment ctaBannerFragment on CTABannerData {
    config {
      asset {
        id
        url
      }
    }
  }
`;

const DONATION_FRAGMENT = gql`
  fragment donationFragment on DonationData {
    settings {
      description
      secondaryTitleAndDescription
      title
      coverTransactionFee
      newsLetterOptIn
    }
    config {
      key
      theme
      secondaryTitle
      secondaryDescription
      asset {
        id
        url
        categoryKey
        serviceImageThumbnail
        serviceVideoThumbnail
      }
    }
  }
`;

const COPY_HEAVY_FRAGMENT = gql`
  fragment copyHeavyFragment on CopyHeavyData {
    settings {
      title
      date
      contributor
      textFields
      primaryAction
      secondaryAction
    }
    config {
      date
      text
      alignment
      contributor {
        id
        firstName
        lastName
        image {
          url
        }
        primaryColor
      }
      primaryAction {
        title
        type
        url
        internalPageType
      }
      secondaryAction {
        title
        type
        url
        internalPageType
      }
    }
  }
`;

const COPY_HEAVY_HERO_FRAGMENT = gql`
  fragment copyHeavyHeroFragment on CopyHeavyHeroData {
    settings {
      title
      date
      contributor
      textFields
      primaryAction
      secondaryAction
    }
    config {
      asset {
        id
        url
      }
      date
      text
      alignment
      contributor {
        id
        firstName
        lastName
        image {
          url
        }
        primaryColor
      }
      primaryAction {
        title
        type
        url
        internalPageType
      }
      secondaryAction {
        title
        type
        url
        internalPageType
      }
    }
  }
`;

const QUOTE_FRAGMENT = gql`
  fragment quoteFragment on QuoteData {
    settings {
      indicators
      authorName
      authorSubTitle
    }
    config {
      quotes {
        text
        author {
          name
          subTitle
          asset {
            id
            url
          }
        }
      }
    }
  }
`;

const LEAD_GEN_FRAGMENT = gql`
  fragment leadGenFragment on LeadGenData {
    settings {
      title
      description
      icon
      image
    }
    config {
      key
      theme
      completionTitle
      completionDescription
      asset {
        id
        url
      }
      iconAsset {
        id
        url
      }
    }
  }
`;
const CONTACT_US_FRAGMENT = gql`
  fragment contactUsFragment on ContactUsData {
    settings {
      title
      description
    }
    config {
      key
    }
  }
`;

const FAQS_FRAGMENT = gql`
  fragment faqsFragment on FAQData {
    settings {
      title
      description
    }
    config {
      questions {
        question
        answer
      }
    }
  }
`;
const SPACER_FRAGMENT = gql`
  fragment spaceFragment on SpacerData {
    config {
      desktop
      mobile
      tablet
      tv
    }
  }
`;

const CTA_BUTTON_LIST_FRAGMENT = gql`
  fragment CTAButtonListDataFragment on CTAButtonListData {
    config {
      items {
        asset {
          id
          url
          title
        }
        buttonType
        internalPageType
        title
        type
        url
      }
    }
    settings {
      buttonSeparator
    }
  }
`;

const CTA_CAROUSEL_FRAGMENT = gql`
  fragment CTACarouselDataFragment on CTACarouselData {
    config {
      items {
        asset {
          id
          title
          url
        }
        icon {
          id
          title
          url
        }
        description
        internalPageType
        title
        type
        url
      }
      ctaCarouselType: type
    }
    settings {
      title
      description
      itemTitle
      itemIcon
      itemImage
    }
  }
`;

const CTA_GRID_FRAGMENT = gql`
  fragment CTAGridDataFragment on CTAGridData {
    config {
      items {
        asset {
          id
          title
          url
        }
        icon {
          id
          title
          url
        }
        description
        internalPageType
        title
        type
        url
      }
      ctaGridType: type
    }
    settings {
      title
      description
      itemImage
      itemTitle
      itemIcon
    }
  }
`;

const FORM_CAROUSEL_FRAGMENT = gql`
  fragment formCarouselData on FormCarouselData {
    settings {
      title
      description
    }
    config {
      autoGenerate
      formAutoGenerateByType: autoGenerateByType
      autoGenerateByData {
        ... on Collection {
          collectionId: id
          collectionTitle: title
        }
      }
      autoGenerateBySubCollectionData {
        id
        title
      }
      formAutoGenerateSortBy: autoGenerateSortBy
      autoGenerateLimit
      type
      formLimit
      sortBy
      forms {
        id
        title
      }
    }
  }
`;
const SOURCE_CAROUSEL_FRAGMENT = gql`
  fragment sourceCarouselFragment on SourceCarouselData {
    settings {
      title
      description
      viewAll
      numberOfVideos
      numberOfPodcasts
      numberOfArticles
      sourceImage
    }
    config {
      method
      autoGenerateLimit
      autoGenerateSortBy
      sources {
        id
        image {
          id
          url
        }
        name
      }
    }
  }
`;

const SOURCE_LIST_FRAGMENT = gql`
  fragment sourceListFragment on SourceListData {
    settings {
      title
      description
      viewAll
      separator
      numberOfVideos
      numberOfArticles
      numberOfPodcasts
      sourceImage
    }
    config {
      method
      autoGenerateLimit
      autoGenerateSortBy
      sources {
        id
        image {
          id
          url
        }
        name
      }
    }
  }
`;
const SOURCE_GRID_FRAGMENT = gql`
  fragment sourceGridFragment on SourceGridData {
    settings {
      title
      description
      viewAll
      separator
      numberOfVideos
      numberOfArticles
      numberOfPodcasts
      sourceImage
    }
    config {
      method
      autoGenerateLimit
      autoGenerateSortBy
      sources {
        id
        image {
          id
          url
        }
        name
      }
    }
  }
`;
const FEATURED_SOURCE_FRAGMENT = gql`
  fragment featuredSourceFragment on FeaturedSourceData {
    settings {
      title
      description
      numberOfVideos
      numberOfPodcasts
      numberOfArticles
      sourceImage
    }
    config {
      method
      autoGenerateSortBy
      source {
        id
        image {
          id
          url
        }
        name
      }
    }
  }
`;

const ARTICLE_GRID_FRAGMENT = gql`
  fragment articleGridFragment on ArticleGridData {
    settings {
      description
      articleDate
      articleAuthor
      articleTopic
      articleReactions
      title
      viewAll
    }
    config {
      method
      articleAutoGenerateByType: autoGenerateByType
      autoGenerateLimit
      autoGenerateByData {
        ... on Contributor {
          contributorId: id
          contributorFirstName: firstName
          contributorLastName: lastName
        }
        ... on Topic {
          topicId: id
          topicName: name
        }
        ... on Tag {
          tagId: id
          tagName: name
        }
        ... on Collection {
          collectionId: id
          collectionTitle: title
        }
        ... on Source {
          sourceId: id
          sourceName: name
        }
      }
      articleAutoGenerateSortBy: autoGenerateSortBy
      articles {
        id
        imageThumbnail {
          url
        }
        title
      }
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const ARTICLE_LIST_FRAGMENT = gql`
  fragment ArticleListDataFragment on ArticleListData {
    settings {
      description
      articleDate
      articleAuthor
      articleTopic
      articleReactions
      title
      viewAll
    }
    config {
      method
      articleAutoGenerateByType: autoGenerateByType
      autoGenerateLimit
      autoGenerateByData {
        ... on Contributor {
          contributorId: id
          contributorFirstName: firstName
          contributorLastName: lastName
        }
        ... on Topic {
          topicId: id
          topicName: name
        }
        ... on Tag {
          tagId: id
          tagName: name
        }
        ... on Collection {
          collectionId: id
          collectionTitle: title
        }
        ... on Source {
          sourceId: id
          sourceName: name
        }
      }
      articleAutoGenerateSortBy: autoGenerateSortBy
      articles {
        id
        imageThumbnail {
          url
        }
        title
      }
      pastContent
      pastContentDays
      deduplication
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const ARTICLE_CAROUSEL_FRAGMENT = gql`
  fragment ArticleCarouselDataFragment on ArticleCarouselData {
    settings {
      title
      description
      articleDate
      articleAuthor
      articleTopic
      articleReactions
      viewAll
    }
    config {
      method
      articleAutoGenerateByType: autoGenerateByType
      autoGenerateLimit
      autoGenerateByData {
        ... on Contributor {
          contributorId: id
          contributorFirstName: firstName
          contributorLastName: lastName
        }
        ... on Topic {
          topicId: id
          topicName: name
        }
        ... on Tag {
          tagId: id
          tagName: name
        }
        ... on Collection {
          collectionId: id
          collectionTitle: title
        }
        ... on Source {
          sourceId: id
          sourceName: name
        }
      }
      articleAutoGenerateSortBy: autoGenerateSortBy
      articles {
        id
        imageThumbnail {
          url
        }
        title
      }
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const FORM_DISPLAY_FRAGMENT = gql`
  fragment formDisplayData on FormDisplayData {
    settings {
      formDescription
      formTitle
    }
    config {
      autoFillForm
      form {
        id
        title
      }
    }
  }
`;

const FEATURED_ARTICLE_SLIDER_FRAGMENT = gql`
  fragment featuredArticleSliderDataFragment on FeaturedArticleSliderData {
    settings {
      title
      description
      articleDate
      articleAuthor
      articleTopic
      articleReactions
    }
    config {
      method
      articleAutoGenerateByType: autoGenerateByType
      autoGenerateByData {
        ... on Contributor {
          contributorId: id
          contributorFirstName: firstName
          contributorLastName: lastName
        }
        ... on Topic {
          topicId: id
          topicName: name
        }
        ... on Tag {
          tagId: id
          tagName: name
        }
        ... on Collection {
          collectionId: id
          collectionTitle: title
        }
        ... on Source {
          sourceId: id
          sourceName: name
        }
      }
      articleAutoGenerateSortBy: autoGenerateSortBy
      articles {
        id
        imageThumbnail {
          url
        }
        title
      }
      pastContent
      pastContentDays
      autoGenerateLimit
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const FEATURED_ARTICLE_FRAGMENT = gql`
  fragment FeaturedArticleDataFragment on FeaturedArticleData {
    settings {
      articleDate
      articleAuthor
      articleTopic
      articleReactions
    }
    config {
      method
      articleAutoGenerateByType: autoGenerateByType
      autoGenerateByData {
        ... on Contributor {
          contributorId: id
          contributorFirstName: firstName
          contributorLastName: lastName
        }
        ... on Topic {
          topicId: id
          topicName: name
        }
        ... on Tag {
          tagId: id
          tagName: name
        }
        ... on Collection {
          collectionId: id
          collectionTitle: title
        }
        ... on Source {
          sourceId: id
          sourceName: name
        }
      }
      articleAutoGenerateSortBy: autoGenerateSortBy
      article {
        id
        imageThumbnail {
          url
        }
        title
      }
      pastContent
      pastContentDays
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const LOOP_ARTICLE_LIST_FRAGMENT = gql`
  fragment LoopArticleListDataFragment on LoopArticleListData {
    config {
      loopConfigMethod: method
      autoGenerateLimit
      pastContent
      pastContentDays
    }
  }
`;

const ARTICLE_CAROUSEL_FEATURED_FRAGMENT = gql`
  fragment ArticleCarouselFeaturedFragment on ArticleFeaturedCarouselData {
    settings {
      title
      description
      articleDate
      articleAuthor
      articleTopic
      articleReactions
      viewAll
      articleSource
      articleKebabMenu
    }
    config {
      method
      articleAutoGenerateByType: autoGenerateByType
      autoGenerateByData {
        ... on Contributor {
          contributorId: id
          contributorFirstName: firstName
          contributorLastName: lastName
        }
        ... on Topic {
          topicId: id
          topicName: name
        }
        ... on Tag {
          tagId: id
          tagName: name
        }
        ... on Collection {
          collectionId: id
          collectionTitle: title
        }
        ... on Source {
          sourceId: id
          sourceName: name
        }
      }
      articleAutoGenerateSortBy: autoGenerateSortBy
      articles {
        id
        imageThumbnail {
          url
        }
        title
        isFeatured
      }
      pastContent
      pastContentDays
      autoGenerateLimit
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const ARTICLE_DISPLAY_FRAGMENT = gql`
  fragment articleDisplayFragment on ArticleDisplayData {
    settings {
      articleAuthor
      articleDate
      articlePray
      articleReactions
      articleSource
      articleTopic
    }
    config {
      method
      articleAutoGenerateByType: autoGenerateByType
      autoGenerateByData {
        ... on Contributor {
          contributorId: id
          contributorFirstName: firstName
          contributorLastName: lastName
        }
        ... on Topic {
          topicId: id
          topicName: name
        }
        ... on Tag {
          tagId: id
          tagName: name
        }
        ... on Collection {
          collectionId: id
          collectionTitle: title
        }
        ... on Source {
          sourceId: id
          sourceName: name
        }
      }
      articleAutoGenerateSortBy: autoGenerateSortBy
      article {
        id
        imageThumbnail {
          url
        }
        title
      }
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const COLLECTION_ITEM_CAROUSEL_FRAGMENT = gql`
  fragment CollectionItemCarouselFragment on CollectionItemCarouselData {
    settings {
      title
      description
      viewAll
    }
    config {
      method
      autoGenerateLimit
      subCollection {
        id
        title
      }
      collection {
        id
        title
        thumbnail {
          url
        }
      }
    }
  }
`;

const COLLECTION_ITEM_GRID_FRAGMENT = gql`
  fragment CollectionItemGridFragment on CollectionItemGridData {
    settings {
      title
      description
      viewAll
    }
    config {
      method
      autoGenerateLimit
      subCollection {
        id
        title
      }
      collection {
        id
        title
        thumbnail {
          url
        }
      }
    }
  }
`;

const COLLECTION_ITEM_LIST_FRAGMENT = gql`
  fragment CollectionItemListFragment on CollectionItemListData {
    settings {
      title
      description
      viewAll
    }
    config {
      method
      autoGenerateLimit
      subCollection {
        id
        title
      }
      collection {
        id
        title
        thumbnail {
          url
        }
      }
    }
  }
`;

const PRAYER_CAROUSEL_FRAGMENT = gql`
  fragment PrayerCarouselFragment on PrayerCarouselData {
    settings {
      title
      description
      viewAll
    }
    config {
      method
      prayerAutoGenerateByType: autoGenerateByType
      prayerAutoGenerateSortBy: autoGenerateSortBy
      autoGenerateLimit
      autoGenerateByData {
        ... on Topic {
          topicId: id
          topicName: name
        }
        ... on Tag {
          tagId: id
          tagName: name
        }
      }
      prayers {
        id
        title
        image {
          id
          url
        }
      }
    }
  }
`;

const FEATURED_AD_INSERT_FRAGMENT = gql`
  fragment featuredAdInsertData on FeaturedAdInsertData {
    config {
      ctaTitle
    }
  }
`;

const FEATURED_PRODUCT = gql`
  fragment featuredProductData on FeaturedProductData {
    config {
      method
      productSortBy: autoGenerateSortBy
      product {
        id
        title
        images
        cursor
      }
      ctaTitle
    }
  }
`;

export const PAGE_MODULE_DETAILS_FRAGMENT = gql`
  ${VIDEO_CONTINUE_WATCHING_CAROUSEL_FRAGMENT}
  ${VIDEO_WATCH_LATER_CAROUSEL_FRAGMENT}
  ${VIDEO_CAROUSEL_FRAGMENT}
  ${VIDEO_LIST_FRAGMENT}
  ${VIDEO_GRID_FRAGMENT}
  ${FEATURED_VIDEO_FRAGMENT}
  ${FEATURED_VIDEO_SLIDER_FRAGMENT}
  ${VIDEO_PLAYER_FRAGMENT}
  ${PODCAST_LISTEN_LATER_CAROUSEL_FRAGMENT}
  ${PODCAST_CONTINUE_LISTENING_CAROUSEL_FRAGMENT}
  ${PODCAST_CAROUSEL_FRAGMENT}
  ${PODCAST_LIST_FRAGMENT}
  ${PODCAST_GRID_FRAGMENT}
  ${FEATURED_PODCAST_FRAGMENT}
  ${FEATURED_PODCAST_SLIDER_FRAGMENT}
  ${PODCAST_PLAYER_FRAGMENT}
  ${COLLECTION_CAROUSEL_FRAGMENT}
  ${COLLECTION_GRID_FRAGMENT}
  ${COLLECTION_LIST_FRAGMENT}
  ${FEATURED_COLLECTION_FRAGMENT}
  ${FEATURED_COLLECTION_SLIDER_FRAGMENT}
  ${CONTRIBUTOR_CAROUSEL_FRAGMENT}
  ${CONTRIBUTOR_LIST_FRAGMENT}
  ${CONTRIBUTOR_GRID_FRAGMENT}
  ${FEATURED_CONTRIBUTOR_FRAGMENT}
  ${TAG_CAROUSEL_FRAGMENT}
  ${TAG_CLOUD_FRAGMENT}
  ${FEATURED_TAG_FRAGMENT}
  ${TOPIC_CAROUSEL_FRAGMENT}
  ${TOPIC_GRID_FRAGMENT}
  ${TOPIC_LIST_FRAGMENT}
  ${FEATURED_TOPIC_FRAGMENT}
  ${CTA_BLOCK_FRAGMENT}
  ${CTA_BANNER_WITH_TEXT_FRAGMENT}
  ${HERO_FRAGMENT}
  ${CTA_BANNER_FRAGMENT}
  ${DONATION_FRAGMENT}
  ${COPY_HEAVY_FRAGMENT}
  ${COPY_HEAVY_HERO_FRAGMENT}
  ${QUOTE_FRAGMENT}
  ${INFORMATION_FRAGMENT}
  ${LEAD_GEN_FRAGMENT}
  ${CONTACT_US_FRAGMENT}
  ${FAQS_FRAGMENT}
  ${SPACER_FRAGMENT}
  ${CTA_BUTTON_LIST_FRAGMENT}
  ${CTA_CAROUSEL_FRAGMENT}
  ${CTA_GRID_FRAGMENT}
  ${FORM_CAROUSEL_FRAGMENT}
  ${FORM_DISPLAY_FRAGMENT}
  ${SOURCE_CAROUSEL_FRAGMENT}
  ${SOURCE_LIST_FRAGMENT}
  ${SOURCE_GRID_FRAGMENT}
  ${ARTICLE_LIST_FRAGMENT}
  ${ARTICLE_CAROUSEL_FRAGMENT}
  ${FEATURED_ARTICLE_FRAGMENT}
  ${ARTICLE_DISPLAY_FRAGMENT}
  ${ARTICLE_CAROUSEL_FEATURED_FRAGMENT}
  ${COLLECTION_ITEM_CAROUSEL_FRAGMENT}
  ${COLLECTION_ITEM_GRID_FRAGMENT}
  ${COLLECTION_ITEM_LIST_FRAGMENT}
  ${ARTICLE_GRID_FRAGMENT}
  ${FEATURED_ARTICLE_SLIDER_FRAGMENT}
  ${FEATURED_SOURCE_FRAGMENT}
  ${PRAYER_CAROUSEL_FRAGMENT}
  ${FEATURED_AD_INSERT_FRAGMENT}
  ${LOOP_ARTICLE_LIST_FRAGMENT}
  ${FEATURED_PRODUCT}
  ${COLLECTION_DETAIL_LIST_FRAGMENT}
  ${COLLECTION_DETAIL_GRID_FRAGMENT}
  fragment pageModuleDetails on PageModule {
    id
    title
    description
    status
    permissions
    type
    isDefaultModule
    moduleData {
      ... on VideoContinueWatchingCarouselData {
        ...videoContinueWatchingCarouselData
      }
      ... on VideoWatchLaterCarouselData {
        ...videoWatchLaterCarouselData
      }
      ... on VideoCarouselData {
        ...videoCarouselData
      }
      ... on VideoListData {
        ...videoListFragment
      }
      ... on VideoGridData {
        ...videoGridFragment
      }
      ... on FeaturedVideoData {
        ...featuredVideoFragment
      }
      ... on FeaturedVideoSliderData {
        ...featuredVideoSlicerFragment
      }
      ... on VideoPlayerData {
        ...videoPlayerFragment
      }

      ... on PodcastListenLaterCarouselData {
        ...podcastListenLaterCarouselData
      }
      ... on PodcastContinueListeningCarouselData {
        ...podcastContinueListeningCarouselData
      }
      ... on PodcastCarouselData {
        ...podcastCarouselData
      }
      ... on PodcastListData {
        ...podcastListFragment
      }
      ... on PodcastGridData {
        ...podcastGridFragment
      }
      ... on FeaturedPodcastData {
        ...featuredPodcastFragment
      }
      ... on FeaturedPodcastSliderData {
        ...featuredPodcastSliderFragment
      }
      ... on PodcastPlayerData {
        ...podcastPlayerFragment
      }
      ... on CollectionCarouselData {
        ...collectionCarouselFragment
      }
      ... on CollectionListData {
        ...collectionListFragment
      }
      ... on CollectionGridData {
        ...collectionGridFragment
      }
      ... on FeaturedCollectionData {
        ...featuredCollectionFragment
      }
      ... on FeaturedCollectionSliderData {
        ...featuredCollectionSliderFragment
      }
      ... on CollectionDetailListData {
        ...collectionDetailListFragment
      }
      ... on CollectionDetailGridData {
        ...collectionDetailGridFragment
      }
      ... on ContributorCarouselData {
        ...contributorCarouselFragment
      }
      ... on ContributorListData {
        ...contributorListFragment
      }
      ... on ContributorGridData {
        ...contributorGridFragment
      }
      ... on FeaturedContributorData {
        ...featuredContributorGridFragment
      }
      ... on TagCarouselData {
        ...tagCarouselFragment
      }
      ... on TagCloudData {
        ...tagCloudFragment
      }
      ... on FeaturedTagData {
        ...featuredTagFragment
      }
      ... on TopicCarouselData {
        ...topicCarouselFragment
      }
      ... on TopicListData {
        ...topicListFragment
      }
      ... on TopicGridData {
        ...topicGridFragment
      }
      ... on FeaturedTopicData {
        ...featuredTopicFragment
      }
      ... on CTABlockData {
        ...ctaBlockFragment
      }
      ... on CTABannerWithTextData {
        ...ctaBannerWithTextFragment
      }
      ... on HeroData {
        ...heroFragment
      }
      ... on CTABannerData {
        ...ctaBannerFragment
      }
      ... on DonationData {
        ...donationFragment
      }
      ... on CopyHeavyData {
        ...copyHeavyFragment
      }
      ... on CopyHeavyHeroData {
        ...copyHeavyHeroFragment
      }
      ... on QuoteData {
        ...quoteFragment
      }
      ... on InformationData {
        ...informationFragment
      }
      ... on LeadGenData {
        ...leadGenFragment
      }
      ... on ContactUsData {
        ...contactUsFragment
      }
      ... on FAQData {
        ...faqsFragment
      }
      ... on SpacerData {
        ...spaceFragment
      }
      ... on CTAButtonListData {
        ...CTAButtonListDataFragment
      }
      ... on CTACarouselData {
        ...CTACarouselDataFragment
      }
      ... on CTAGridData {
        ...CTAGridDataFragment
      }
      ... on FormCarouselData {
        ...formCarouselData
      }
      ... on FormDisplayData {
        ...formDisplayData
      }
      ... on SourceCarouselData {
        ...sourceCarouselFragment
      }
      ... on SourceListData {
        ...sourceListFragment
      }
      ... on SourceGridData {
        ...sourceGridFragment
      }
      ... on FeaturedSourceData {
        ...featuredSourceFragment
      }
      ... on ArticleGridData {
        ...articleGridFragment
      }
      ... on ArticleListData {
        ...ArticleListDataFragment
      }

      ... on ArticleCarouselData {
        ...ArticleCarouselDataFragment
      }

      ... on FeaturedArticleData {
        ...FeaturedArticleDataFragment
      }
      ... on ArticleDisplayData {
        ...articleDisplayFragment
      }
      ... on FeaturedArticleSliderData {
        ...featuredArticleSliderDataFragment
      }
      ... on ArticleFeaturedCarouselData {
        ...ArticleCarouselFeaturedFragment
      }
      ... on CollectionItemCarouselData {
        ...CollectionItemCarouselFragment
      }
      ... on CollectionItemGridData {
        ...CollectionItemGridFragment
      }
      ... on CollectionItemListData {
        ...CollectionItemListFragment
      }
      ... on PrayerCarouselData {
        ...PrayerCarouselFragment
      }
      ... on FeaturedAdInsertData {
        ...featuredAdInsertData
      }
      ... on LoopArticleListData {
        ...LoopArticleListDataFragment
      }
      ... on FeaturedProductData {
        ...featuredProductData
      }
    }
  }
`;

export const GET_PAGE_DETAILS = gql`
  ${PAGE_MODULE_DETAILS_FRAGMENT}
  query pageAdmin($id: ID!) {
    pageAdmin(where: { id: $id }) {
      slug
      title
      description
      metaHeader
      metaFooter
      permissions
      allowDelete
      isDefault
      type
      generationType
      apps {
        id
        name
      }
      modules {
        ...pageModuleDetails
      }
    }
  }
`;

export const GET_PAGE_DETAILS_FOR_PREVIEW = gql`
  fragment AssetFragment on Asset {
    categoryKey
    url
    blurhash
    serviceVideoThumbnail
    serviceImageThumbnail
  }

  fragment ContributorFragment on Contributor {
    id
    firstName
    lastName
    subTitle
    description
    image {
      url
      blurhash
    }
    thumbnail {
      url
      blurhash
    }
    primaryColor
    videosCount
  }

  fragment TopicFragment on Topic {
    id
    key
    name
    primaryColor
  }

  fragment TagFragment on Tag {
    id
    name
  }

  fragment VideoFragment on Video {
    id
    title
    description
    topics {
      id
      name
      primaryColor
    }
    collections {
      id
      slug
      title
    }
    speakers {
      id
      firstName
      lastName
      image {
        url
        blurhash
      }
    }
    tags {
      id
      name
    }
    createdAt
    video: videoAsset {
      id
      url
      information {
        durationInSeconds
      }
    }
    videoThumbnail {
      url
      blurhash
    }
  }

  fragment PodcastFragment on Podcast {
    id
    code
    contentRating
    createdAt
    description
    speakers {
      id
      firstName
      lastName
      image {
        url
        blurhash
      }
    }
    image {
      url
      blurhash
    }
    imageThumbnail {
      url
      blurhash
    }
    metaHeader
    metaFooter
    permissions
    slug
    tags {
      name
      id
    }
    title
    topics {
      key
      name
      id
      primaryColor
    }
    audio {
      id
      url
      serviceImageThumbnail
      information {
        durationInSeconds
      }
    }
  }

  fragment CollectionFragment on Collection {
    id
    title
    itemsCount
    thumbnail {
      url
      blurhash
    }
  }

  fragment VideoContinueWatchingCarouselFragment on VideoContinueWatchingCarouselData {
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment VideoWatchLaterCarouselFragment on VideoWatchLaterCarouselData {
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment VideoCarouselFragment on VideoCarouselData {
    settings {
      videoProgressBar
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment VideoListFragment on VideoListData {
    settings {
      videoProgressBar
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment VideoGridFragment on VideoGridData {
    settings {
      videoProgressBar
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment FeaturedVideoSliderFragment on FeaturedVideoSliderData {
    settings {
      carouselIndicator
      videoCTA
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment FeaturedVideoFragment on FeaturedVideoData {
    config {
      video {
        ...VideoFragment
      }
    }
  }

  fragment VideoPlayerFragment on VideoPlayerData {
    config {
      video {
        ...VideoFragment
      }
    }
  }

  fragment VideoPlayerV2Fragment on VideoPlayerDataV2 {
    config {
      video {
        ...VideoFragment
        imageThumbnail {
          url
          blurhash
        }
      }
    }
  }

  fragment PodcastListenLaterCarouselFragment on PodcastListenLaterCarouselData {
    settings {
      viewAll
    }
    config {
      podcasts {
        ...PodcastFragment
        order
      }
    }
  }

  fragment PodcastContinueListeningCarouselFragment on PodcastContinueListeningCarouselData {
    settings {
      viewAll
    }
    config {
      podcasts {
        ...PodcastFragment
        order
      }
    }
  }

  fragment PodcastCarouselFragment on PodcastCarouselData {
    settings {
      podcastProgressBar
    }
    config {
      method
      podcasts {
        ...PodcastFragment
        order
      }
    }
  }

  fragment PodcastListFragment on PodcastListData {
    settings {
      podcastProgressBar
      viewAll
    }
    config {
      podcasts {
        ...PodcastFragment
        order
      }
    }
  }

  fragment PodcastGridFragment on PodcastGridData {
    settings {
      podcastProgressBar
    }
    config {
      podcasts {
        ...PodcastFragment
        order
      }
    }
  }

  fragment FeaturedPodcastSliderFragment on FeaturedPodcastSliderData {
    config {
      podcasts {
        ...PodcastFragment
        order
      }
    }
  }

  fragment FeaturedPodcastFragment on FeaturedPodcastData {
    config {
      podcast {
        ...PodcastFragment
      }
    }
  }

  fragment PodcastPlayerFragment on PodcastPlayerData {
    config {
      podcast {
        ...PodcastFragment
      }
    }
  }

  fragment TopicCarouselFragment on TopicCarouselData {
    settings {
      viewAll
    }
    config {
      topics {
        ...TopicFragment
        order
      }
    }
  }

  fragment TopicListFragment on TopicListData {
    settings {
      viewAll
    }
    config {
      topics {
        ...TopicFragment
        order
      }
    }
  }

  fragment TopicGridFragment on TopicGridData {
    settings {
      viewAll
    }
    config {
      topics {
        ...TopicFragment
        order
      }
    }
  }

  fragment FeaturedTopicFragment on FeaturedTopicData {
    settings {
      topicTitle
    }
    featuredTopicConfig: config {
      autoGenerateSortBy
      topic {
        ...TopicFragment
      }
    }
  }

  fragment CollectionCarouselFragment on CollectionCarouselData {
    settings {
      viewAll
      numberOfItems
    }
    config {
      aspectRatio
      collections {
        ...CollectionFragment
        order
      }
    }
  }

  fragment CollectionGridFragment on CollectionGridData {
    settings {
      numberOfItems
      viewAll
    }
    config {
      aspectRatio
      collections {
        ...CollectionFragment
        order
      }
    }
  }

  fragment CollectionListFragment on CollectionListData {
    settings {
      separator
      numberOfItems
      viewAll
    }
    config {
      aspectRatio
      collections {
        ...CollectionFragment
        order
      }
    }
  }

  fragment FeaturedCollectionSliderFragment on FeaturedCollectionSliderData {
    config {
      collections {
        ...CollectionFragment
        order
      }
    }
  }

  fragment FeaturedCollectionFragment on FeaturedCollectionData {
    settings {
      numberOfItems
    }
    config {
      collection {
        ...CollectionFragment
      }
    }
  }

  fragment ContributorCarouselFragment on ContributorCarouselData {
    settings {
      viewAll
    }
    config {
      contributors {
        ...ContributorFragment
        order
      }
    }
  }

  fragment ContributorGridFragment on ContributorGridData {
    settings {
      viewAll
    }
    config {
      contributors {
        ...ContributorFragment
        order
      }
    }
  }

  fragment ContributorListFragment on ContributorListData {
    settings {
      separator
      viewAll
    }
    config {
      contributors {
        ...ContributorFragment
        order
      }
    }
  }

  fragment FeaturedContributorFragment on FeaturedContributorData {
    config {
      contributor {
        ...ContributorFragment
      }
    }
  }

  fragment TagCarouselFragment on TagCarouselData {
    settings {
      viewAll
    }
    config {
      tags {
        ...TagFragment
      }
    }
  }

  fragment TagCloudFragment on TagCloudData {
    settings {
      viewAll
    }
    config {
      tags {
        ...TagFragment
      }
    }
  }

  fragment FeaturedTagFragment on FeaturedTagData {
    settings {
      tagTitle
    }
    config {
      autoGenerateSortBy
      tag {
        ...TagFragment
      }
    }
  }

  fragment CTABlockFragment on CTABlockData {
    config {
      asset {
        ...AssetFragment
      }
      primaryAction {
        asset {
          ...AssetFragment
        }
        title
        url
        type
        internalPageType
      }
      secondaryAction {
        asset {
          ...AssetFragment
        }
        title
        url
        type
        internalPageType
      }
      theme
      eventTime
      eventDate
      eventLocation
    }
  }
  fragment CTABannerWithTextFragment on CTABannerWithTextData {
    config {
      asset {
        ...AssetFragment
      }
      primaryAction {
        title
        url
        type
        internalPageType
      }
      theme
    }
  }
  fragment HeroFragment on HeroData {
    config {
      asset {
        ...AssetFragment
      }
      eventTime
      eventDate
      eventLocation
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          ...AssetFragment
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          ...AssetFragment
        }
      }
      alignment
    }
  }

  fragment CTABannerFragment on CTABannerData {
    config {
      asset {
        ...AssetFragment
      }
    }
  }
  fragment DonationFragment on DonationData {
    config {
      theme
      secondaryTitle
      secondaryDescription
    }
  }
  fragment CopyHeavyFragment on CopyHeavyData {
    config {
      date
      text
      alignment
      contributor {
        id
        firstName
        lastName
        subTitle
        image {
          url
          blurhash
        }
        primaryColor
      }
      primaryAction {
        title
        type
        url
        internalPageType
      }
      secondaryAction {
        title
        type
        url
        internalPageType
      }
    }
  }

  fragment CopyHeavyHeroFragment on CopyHeavyHeroData {
    config {
      asset {
        ...AssetFragment
      }
      date
      text
      alignment
      contributor {
        id
        firstName
        lastName
        subTitle
        image {
          url
          blurhash
        }
        primaryColor
      }
      primaryAction {
        title
        type
        url
        internalPageType
      }
      secondaryAction {
        title
        type
        url
        internalPageType
      }
    }
  }

  fragment QuoteFragment on QuoteData {
    settings {
      indicators
    }
    config {
      quotes {
        text
        author {
          name
          subTitle
          asset {
            ...AssetFragment
          }
        }
      }
    }
  }

  fragment informationFragment on InformationData {
    config {
      asset {
        url
        blurhash
      }
      theme
      imageOrientation
      imageType
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          ...AssetFragment
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          ...AssetFragment
        }
      }
    }
  }

  fragment leadGenFragment on LeadGenData {
    config {
      key
      theme
      asset {
        id
        url
      }
      iconAsset {
        id
        url
      }
    }
  }
  fragment contactUsFragment on ContactUsData {
    config {
      key
    }
  }

  fragment faqsFragment on FAQData {
    config {
      questions {
        question
        answer
      }
    }
  }

  fragment spaceFragment on SpacerData {
    config {
      desktop
      mobile
      tablet
      tv
    }
  }
  fragment CTAGridFragment on CTAGridData {
    settings {
      viewAll
    }
    config {
      items {
        url
        type
        title
        internalPageType
        description
        asset {
          ...AssetFragment
        }
      }
      ctaGridType: type
    }
  }

  fragment CTACarouselFragment on CTACarouselData {
    settings {
      viewAll
    }
    config {
      items {
        url
        type
        title
        internalPageType
        description
        asset {
          ...AssetFragment
        }
      }
      ctaCarouselType: type
    }
  }

  fragment CTAButtonListFragment on CTAButtonListData {
    settings {
      buttonSeparator
    }
    config {
      items {
        url
        type
        title
        internalPageType
        buttonType
        asset {
          ...AssetFragment
        }
      }
    }
  }

  fragment articleGridDataFragment on ArticleGridData {
    settings {
      description
      articleTimestamp
      articleAuthor
      articleTopic
      articleReactions
    }
    config {
      articleAutoGenerateByType: autoGenerateByType
      autoGenerateByData {
        ... on Contributor {
          contributorId: id
          contributorFirstName: firstName
          contributorLastName: lastName
        }
        ... on Topic {
          topicId: id
          topicName: name
        }
        ... on Tag {
          tagId: id
          tagName: name
        }
        ... on Collection {
          collectionId: id
          collectionTitle: title
        }
      }
      articleAutoGenerateSortBy: autoGenerateSortBy
      articles {
        id
        imageThumbnail {
          url
        }
        title
      }
    }
  }

  fragment articleDisplayFragment on ArticleDisplayData {
    settings {
      articleAuthor
      articleDate
      articlePray
      articleReactions
      articleSource
      articleTopic
    }
    config {
      method
      articleAutoGenerateByType: autoGenerateByType
      autoGenerateByData {
        ... on Contributor {
          contributorId: id
          contributorFirstName: firstName
          contributorLastName: lastName
        }
        ... on Topic {
          topicId: id
          topicName: name
        }
        ... on Tag {
          tagId: id
          tagName: name
        }
        ... on Collection {
          collectionId: id
          collectionTitle: title
        }
        ... on Source {
          sourceId: id
          sourceName: name
        }
      }
      articleAutoGenerateSortBy: autoGenerateSortBy
      article {
        id
        imageThumbnail {
          url
        }
        title
      }
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }

  fragment articleListFragment on ArticleListData {
    config {
      articles {
        id
        title
        description
        publishedAt
        createdAt
        authors {
          firstName
          lastName
          thumbnail {
            url
            blurhash
          }
          id
          slug
        }
        tags {
          id
          name
          slug
        }
        topics {
          id
          name
          slug
          primaryColor
        }
        prayerCount
        commentCount
        slug
        imageThumbnail {
          id
          url
          blurhash
        }
      }
    }
  }

  fragment articleListV2Fragment on ArticleListDataV2 {
    config {
      articles {
        id
        title
        description
        publishedAt
        createdAt
        authors {
          firstName
          lastName
          thumbnail {
            url
            blurhash
          }
          id
          slug
        }
        tags {
          id
          name
          slug
        }
        topics {
          id
          name
          slug
          primaryColor
        }
        prayerCount
        commentCount
        slug
        imageThumbnail {
          id
          url
          blurhash
        }
      }
    }
  }

  fragment featuredArticleSliderDataFragment on FeaturedArticleSliderData {
    settings {
      description
      articleTimestamp
      articleAuthor
      articleTopic
      articleReactions
    }
    config {
      method
      articleAutoGenerateByType: autoGenerateByType
      autoGenerateByData {
        ... on Contributor {
          contributorId: id
          contributorFirstName: firstName
          contributorLastName: lastName
        }
        ... on Topic {
          topicId: id
          topicName: name
        }
        ... on Tag {
          tagId: id
          tagName: name
        }
        ... on Collection {
          collectionId: id
          collectionTitle: title
        }
      }
      articleAutoGenerateSortBy: autoGenerateSortBy
      article {
        id
        imageThumbnail {
          url
        }
        title
      }
    }
  }

  fragment featuredArticleFragment on FeaturedArticleData {
    config {
      article {
        publishedAt
        html
        authors {
          firstName
          lastName
          subTitle
          thumbnail {
            url
            blurhash
          }
          image {
            blurhash
            url
          }
          id
          slug
        }
        tags {
          id
          name
          key
          slug
        }
        topics {
          id
          name
          slug
          primaryColor
        }
        prayerCount
        title
        description
        slug
        commentCount
        imageThumbnail {
          id
          url
          blurhash
        }
      }
    }
  }

  fragment featuredArticleV2Fragment on FeaturedArticleDataV2 {
    config {
      article {
        html
        title
        id
      }
    }
  }

  fragment articleCarouselFragment on ArticleCarouselData {
    settings {
      articleTimestamp
    }
    config {
      articles {
        publishedAt
        slug
        id
        authors {
          firstName
          lastName
          subTitle
          thumbnail {
            url
            blurhash
          }
          image {
            blurhash
            url
          }
          id
          slug
        }
        tags {
          id
          key
          name
          slug
        }
        topics {
          id
          name
          slug
          primaryColor
        }
        description
        prayerCount
        commentCount
        title
        imageThumbnail {
          id
          url
          blurhash
        }
      }
    }
  }

  fragment articleCarouselV2Fragment on ArticleCarouselDataV2 {
    settings {
      articleTimestamp
    }
    config {
      articles {
        publishedAt
        slug
        id
        html
        authors {
          firstName
          lastName
          subTitle
          thumbnail {
            url
            blurhash
          }
          image {
            blurhash
            url
          }
          id
          slug
        }
        tags {
          id
          key
          name
          slug
        }
        topics {
          id
          name
          slug
          primaryColor
        }
        description
        prayerCount
        commentCount
        title
        imageThumbnail {
          id
          url
          blurhash
        }
      }
    }
  }

  fragment formDisplayFragment on FormDisplayData {
    config {
      autoFillForm
      form {
        id
        title
        submitCount
        description
        slug
        ctas {
          icon {
            url
            id
          }
          actionUrl
          action
          label
          type
        }
        type
        fields {
          key
          label
          order
          type
          required
          options {
            key
            label
            order
          }
        }
      }
    }
  }

  fragment formCarouselFragment on FormCarouselData {
    config {
      formLimit
      type
      sortBy
      forms {
        id
        title
        description
        slug
        prayerCount
        submitCount
        type
        ctas {
          label
          type
          actionUrl
          action
          icon {
            id
            url
          }
        }
      }
    }
  }

  query pageAdmin($id: ID!) {
    pageAdmin(where: { id: $id }, data: { applySettings: true }) {
      slug
      type
      modules {
        id
        type
        order
        title
        description
        moduleData {
          ... on VideoContinueWatchingCarouselData {
            ...VideoContinueWatchingCarouselFragment
          }
          ... on VideoWatchLaterCarouselData {
            ...VideoWatchLaterCarouselFragment
          }
          ... on VideoCarouselData {
            ...VideoCarouselFragment
          }
          ... on VideoListData {
            ...VideoListFragment
          }
          ... on VideoGridData {
            ...VideoGridFragment
          }
          ... on FeaturedVideoData {
            ...FeaturedVideoFragment
          }
          ... on VideoPlayerData {
            ...VideoPlayerFragment
          }
          ... on VideoPlayerDataV2 {
            ...VideoPlayerV2Fragment
          }
          ... on FeaturedVideoSliderData {
            ...FeaturedVideoSliderFragment
          }
          ... on PodcastListenLaterCarouselData {
            ...PodcastListenLaterCarouselFragment
          }
          ... on PodcastContinueListeningCarouselData {
            ...PodcastContinueListeningCarouselFragment
          }
          ... on PodcastCarouselData {
            ...PodcastCarouselFragment
          }
          ... on PodcastListData {
            ...PodcastListFragment
          }
          ... on PodcastGridData {
            ...PodcastGridFragment
          }
          ... on FeaturedPodcastData {
            ...FeaturedPodcastFragment
          }
          ... on FeaturedPodcastSliderData {
            ...FeaturedPodcastSliderFragment
          }
          ... on PodcastPlayerData {
            ...PodcastPlayerFragment
          }
          ... on CollectionGridData {
            ...CollectionGridFragment
          }
          ... on CollectionCarouselData {
            ...CollectionCarouselFragment
          }
          ... on CollectionListData {
            ...CollectionListFragment
          }
          ... on FeaturedCollectionData {
            ...FeaturedCollectionFragment
          }
          ... on FeaturedCollectionSliderData {
            ...FeaturedCollectionSliderFragment
          }
          ... on ContributorListData {
            ...ContributorListFragment
          }
          ... on ContributorGridData {
            ...ContributorGridFragment
          }
          ... on ContributorCarouselData {
            ...ContributorCarouselFragment
          }
          ... on FeaturedContributorData {
            ...FeaturedContributorFragment
          }
          ... on TopicListData {
            ...TopicListFragment
          }
          ... on TopicGridData {
            ...TopicGridFragment
          }
          ... on TopicCarouselData {
            ...TopicCarouselFragment
          }
          ... on FeaturedTopicData {
            ...FeaturedTopicFragment
          }
          ... on TagCloudData {
            ...TagCloudFragment
          }
          ... on TagCarouselData {
            ...TagCarouselFragment
          }
          ... on FeaturedTagData {
            ...FeaturedTagFragment
          }
          ... on CTABlockData {
            ...CTABlockFragment
          }
          ... on CTABannerWithTextData {
            ...CTABannerWithTextFragment
          }
          ... on HeroData {
            ...HeroFragment
          }
          ... on CTABannerData {
            ...CTABannerFragment
          }
          ... on DonationData {
            ...DonationFragment
          }
          ... on CopyHeavyData {
            ...CopyHeavyFragment
          }
          ... on CopyHeavyHeroData {
            ...CopyHeavyHeroFragment
          }
          ... on QuoteData {
            ...QuoteFragment
          }
          ... on InformationData {
            ...informationFragment
          }
          ... on LeadGenData {
            ...leadGenFragment
          }
          ... on ContactUsData {
            ...contactUsFragment
          }
          ... on FAQData {
            ...faqsFragment
          }
          ... on SpacerData {
            ...spaceFragment
          }
          ... on CTAGridData {
            ...CTAGridFragment
          }
          ... on CTACarouselData {
            ...CTACarouselFragment
          }
          ... on CTAButtonListData {
            ...CTAButtonListFragment
          }
          ... on ArticleCarouselData {
            ...articleCarouselFragment
          }
          ... on ArticleCarouselDataV2 {
            ...articleCarouselV2Fragment
          }
          ... on ArticleGridData {
            ...articleGridFragment
          }
          ... on ArticleDisplayData {
            ...articleDisplayFragment
          }
          ... on ArticleListData {
            ...articleListFragment
          }
          ... on ArticleListDataV2 {
            ...articleListV2Fragment
          }
          ... on FeaturedArticleData {
            ...featuredArticleFragment
          }
          ... on FeaturedArticleDataV2 {
            ...featuredArticleV2Fragment
          }
          ... on FormDisplayData {
            ...formDisplayFragment
          }
          ... on FormCarouselData {
            ...formCarouselFragment
          }
        }
      }
    }
  }
`;

export const GET_WORKSPACE_APP = gql`
  query workspaceApp($where: WorkspaceAppUniqueInput!) {
    workspaceApp(where: $where) {
      id
      url
    }
  }
`;

export const GET_PRODUCTS = gql`
  query Products($filter: ProductsFilter!) {
    products(filter: $filter) {
      id
      cursor
      title
      images
    }
  }
`;
