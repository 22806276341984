import { useMutation } from '@apollo/client';
import { Button, Flex, Tooltip } from 'antd';
import { map } from 'lodash';
import { Export } from 'phosphor-react';
import React, { useMemo, useState } from 'react';
import {
  FORM_TYPES,
  LIST_TYPES,
  MODULES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import ProgressBar from '../../components/ProgressBar';
import useCheckPermission from '../../hooks/useCheckPermission';
import { REQUEST_FORM_RESPONSE_EXPORT } from './graphql/Mutations';
import { FORM_RESPONSES } from './graphql/Queries';

const variablesSelector = ({ offset, limit, id }) => ({
  filter: {
    skip: offset,
    limit
  },
  where: { id }
});

const dataSelector = (data) => ({
  data: data?.userFormResponsesAdmin?.formResponses ?? [],
  count: data?.userFormResponsesAdmin?.count ?? 0
});

const FormResponses = ({ match: { params }, history: { location } }) => {
  const [keys, setKeys] = useState([]);
  const filterProps = useFilterBar();
  const { formId: id } = params;
  const search = new URLSearchParams(location?.search);
  const type = search?.get('type');

  const [request, { loading: isExporting }] = useMutation(
    REQUEST_FORM_RESPONSE_EXPORT,
    {
      onError() {},
      variables: {
        where: {
          id
        }
      }
    }
  );

  const columns = useMemo(
    () =>
      keys.map((key) => ({
        title: key,
        dataIndex: key,
        ellipsis: true,
        render: (value) => {
          if (type !== FORM_TYPES.POLL) {
            return value || '-';
          }
          return map(value, (item, itemKey) => {
            return (
              <Flex vertical>
                {itemKey}
                <ProgressBar
                  progress={Number(item)}
                  size={[500, 15]}
                  showInfo
                  className="poll-progress"
                />
              </Flex>
            );
          });
        }
      })),
    [keys]
  );

  const filters = useMemo(
    () => ({
      id
    }),
    [id]
  );

  const isExportAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.REPORT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const handleExport = () => {
    request();
  };

  const handleLoadData = (data) => {
    if (data?.length > 0) {
      const record = data[0];
      setKeys(Object.keys(record));
    }
  };

  return (
    <>
      <PageHeader menu={MODULES.CONTENT_UNITS} />
      <FilterBar
        {...filterProps}
        show={{
          listModes: false,
          search: false,
          sort: false
        }}
        SearchProps={{ placeholder: 'Search Users' }}
      >
        {isExportAllowed && (
          <Tooltip title="Export Form Responses" placement="left">
            <Button
              className="text-btn d-flex align-center justify-center"
              type="text"
              icon={<Export size={24} />}
              onClick={handleExport}
              loading={isExporting}
            />
          </Tooltip>
        )}
      </FilterBar>
      <PageList
        filters={filters}
        listMode={LIST_TYPES.TABLE}
        query={FORM_RESPONSES}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{
          columns,
          className: 'form-response-table'
        }}
        limit={20}
        onLoad={handleLoadData}
      />
    </>
  );
};

export default FormResponses;
