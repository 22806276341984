import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, Form, Row, Select, Space } from 'antd';
import { omit } from 'lodash';
import moment from 'moment';
import { CalendarBlank } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import {
  ROUTES,
  STATUS_OPTIONS,
  UNPUBLISHED_STATUS
} from '../../common/constants';
import PageHeader from '../../components/PageHeader';
import { SelectContentUnits } from '../labels/collections/components/FormInputs';
import { DatePicker } from '../videos/components/FormInputs';
import { CREATE_LOOP, UPDATE_LOOP } from './graphql/Mutations';
import { GET_LOOP } from './graphql/Queries';

const AddEditLoop = ({ location, history, match: { params } }) => {
  const [form] = Form.useForm();
  const { date = moment() } = location;
  const { loopId } = params;
  const startDate = Form.useWatch('startDate', form);
  const [isPastDate, setIsPastDate] = useState(false);

  const [createUpdateLoop, { loading }] = useMutation(
    loopId ? UPDATE_LOOP : CREATE_LOOP,
    {
      onError() {}
    }
  );

  const [getLoop, { loading: fetchingDetails }] = useLazyQuery(GET_LOOP, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      form.setFieldsValue({
        startDate: new Date(
          moment(res?.loopAdmin?.startDate)
            .utc()
            .tz(moment.tz.guess(), true)
            .format()
        ),
        contents: res?.loopAdmin?.contents?.map((item) => ({
          id: item?.instanceId,
          title: item?.title,
          instanceType: item?.instanceType,
          url:
            item?.instanceData?.imageThumbnail?.url ??
            item?.instanceData?.image?.url ??
            '',
          summary: item?.summary ?? null
        })),
        status: res?.loopAdmin?.status
      });
    },
    onError() {}
  });

  useEffect(() => {
    if (date && !loopId) {
      form.setFieldsValue({
        startDate: moment(date)
      });
    }
  }, [date]);

  useEffect(() => {
    if (loopId) {
      getLoop({
        variables: {
          where: {
            id: loopId
          }
        }
      });
    }
  }, [loopId]);

  useEffect(() => {
    if (moment(startDate).isBefore(moment(), 'day')) {
      setIsPastDate(true);
    } else {
      setIsPastDate(false);
    }
  }, [startDate]);

  const handleCancel = () => {
    history.replace(ROUTES.LOOP);
  };

  const handleSubmit = (values) => {
    const updatedContents = values?.contents?.map((item, index) =>
      omit(
        {
          ...item,
          instanceId: item?.id,
          order: index + 1
        },
        ['id', 'url', '__typename']
      )
    );
    const payload = {
      contents: updatedContents
    };
    if (!loopId) {
      payload.startDate = moment(values?.startDate).format('YYYY-MM-DD');
    } else {
      payload.status = values?.status;
    }
    createUpdateLoop({
      variables: {
        data: payload,
        ...(loopId && {
          where: {
            id: loopId
          }
        })
      }
    }).then((res) => {
      if (res?.data?.createLoop || res?.data?.updateLoop)
        history.push(ROUTES.LOOP);
    });
  };

  return (
    <div>
      <PageHeader />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form"
            layout="vertical"
            onFinish={handleSubmit}
            disabled={loading || fetchingDetails || isPastDate}
          >
            <Row gutter={[16, 0]}>
              <Col md={24} lg={12}>
                <Form.Item label="Start Date" name="startDate">
                  <DatePicker
                    suffixIcon={<CalendarBlank size={20} />}
                    disabled={isPastDate}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col md={24} lg={12}>
                <Form.Item
                  label="Content"
                  name="contents"
                  rules={[
                    {
                      required: true,
                      message: 'Please select at least one content!'
                    }
                  ]}
                >
                  <SelectContentUnits
                    btnText="Content"
                    allowDrag={!isPastDate}
                    disabled={isPastDate}
                  />
                </Form.Item>
              </Col>
            </Row>
            {loopId && (
              <Form.Item label="Status" name="status">
                <Select
                  options={[...STATUS_OPTIONS, UNPUBLISHED_STATUS].map(
                    ({ name, value }) => ({
                      label: name,
                      value
                    })
                  )}
                  placeholder="Select status"
                />
              </Form.Item>
            )}
            <div className="d-flex button-section mb-8">
              <Space>
                {!isPastDate && (
                  <Button
                    disabled={loading || fetchingDetails}
                    loading={loading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}

                <Button
                  disabled={loading || fetchingDetails}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddEditLoop;
