import { useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Form,
  Input,
  Space,
  Typography
} from 'antd';
import React, { useEffect } from 'react';
import {
  AUTO_GENERATED_SORT_BY_OPTIONS,
  AUTO_GENERATED_TYPES,
  CONFIG_METHOD_OPTIONS,
  CONFIG_METHOD_TYPES,
  MODULE_TYPES,
  STATIC_DATA_KEYS,
  STATUS_TYPES,
  getAutoGenerateByDataValue,
  getAutoGeneratedByIdProps
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import useStaticData from '../../../../../hooks/useStaticData';
import { SelectPrayers } from '../../../../labels/collections/components/FormInputs';
import { Select } from '../../../../videos/components/FormInputs';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ModuleFields, Permissions, ShowFields } from './FormInputs';

const initialValues = {
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  isDefaultModule: false,
  settings: {
    title: true,
    description: true,
    viewAll: true
  },
  config: {
    method: null,
    autoGenerateByType: null,
    autoGenerateById: null,
    autoGenerateLimit: null,
    autoGenerateSortBy: null,
    prayers: []
  }
};

const AUTO_GENERATED_OPTIONS = [
  {
    label: 'Topic',
    value: AUTO_GENERATED_TYPES.TOPIC
  },
  {
    label: 'Tag',
    value: AUTO_GENERATED_TYPES.TAG
  }
];

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [MODULE_TYPES.PRAYER_CAROUSEL]
  },
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [MODULE_TYPES.PRAYER_CAROUSEL]
  },
  {
    name: 'viewAll',
    label: 'View All Button',
    allowedTypes: [MODULE_TYPES.PRAYER_CAROUSEL]
  }
];

const MODULE_KEYS = {
  [MODULE_TYPES.PRAYER_CAROUSEL]: 'prayerCarouselModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.PRAYER_CAROUSEL]: 'Prayer Carousel Configs'
};

const PrayerForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;
  const isMultiPrayer = [MODULE_TYPES.PRAYER_CAROUSEL].includes(type);
  const { data: configData } = useStaticData(STATIC_DATA_KEYS.CONFIGS);

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  const configProps = Form.useWatch(['config'], form);
  const { autoGenerateByType, method } = configProps ?? initialValues.config;

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          method: defaultValues?.moduleData?.config?.method ?? null,
          autoGenerateByType:
            defaultValues?.moduleData?.config?.prayerAutoGenerateByType || null,
          autoGenerateById: defaultValues?.moduleData?.config
            ?.autoGenerateByData
            ? getAutoGenerateByDataValue[
                defaultValues?.moduleData?.config?.prayerAutoGenerateByType
              ]?.(defaultValues?.moduleData?.config?.autoGenerateByData)
            : null,
          autoGenerateSortBy:
            defaultValues?.moduleData?.config?.prayerAutoGenerateSortBy || null,
          autoGenerateLimit:
            defaultValues?.moduleData?.config?.autoGenerateLimit || null,
          prayers: isMultiPrayer
            ? defaultValues?.moduleData?.config?.prayers?.map(
                ({ id, title, image }) => ({
                  id,
                  title,
                  url: image?.url ?? ''
                })
              ) || []
            : {
                id: '',
                title: '',
                url: ''
              }
        }
      });
    }
  }, [
    form,
    moduleId,
    formType,
    defaultValues,
    form,
    initialValues,
    isMultiPrayer
  ]);

  const handleSubmit = ({ config, settings, permissions, ...rest }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;

    const {
      prayers,
      method: prayerMethod,
      autoGenerateById,
      autoGenerateLimit,
      ...restConfig
    } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          method: prayerMethod ?? CONFIG_METHOD_TYPES.MANUAL,
          autoGenerateById: autoGenerateById?.value,
          ...restConfig,
          ...(isMultiPrayer && {
            prayers:
              prayers?.map(({ id }, i) => ({
                prayerId: id,
                order: i + 1
              })) ?? []
          }),
          ...(isMultiPrayer && {
            autoGenerateLimit: autoGenerateLimit
              ? Number(autoGenerateLimit)
              : null
          })
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  const autoGenerateByIdProps = getAutoGeneratedByIdProps[autoGenerateByType];

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        <Space className="w-full" direction="vertical">
          <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
          <div>
            <Form.Item label="Config Method" name={['config', 'method']}>
              <AntdSelect
                options={CONFIG_METHOD_OPTIONS?.filter(
                  ({ value }) => value !== CONFIG_METHOD_TYPES.RECOMMENDED
                )}
                placeholder="Select method"
              />
            </Form.Item>
            {method === CONFIG_METHOD_TYPES.AUTO_GENERATED && (
              <>
                <Form.Item
                  label="Auto Generated By Type"
                  name={['config', 'autoGenerateByType']}
                >
                  <AntdSelect
                    options={AUTO_GENERATED_OPTIONS}
                    placeholder="Select type"
                    onChange={() => {
                      form.setFieldValue(['config', 'autoGenerateById'], null);
                    }}
                  />
                </Form.Item>

                {autoGenerateByType && autoGenerateByIdProps && (
                  <Form.Item
                    label="Auto Generated By"
                    name={['config', 'autoGenerateById']}
                  >
                    <Select
                      placeholder="Select"
                      query={autoGenerateByIdProps?.query}
                      variablesSelector={(filter) => ({
                        filter,
                        ...(autoGenerateByType ===
                          AUTO_GENERATED_TYPES.CONTRIBUTOR && {
                          where: { isActive: true }
                        })
                      })}
                      dataSelector={autoGenerateByIdProps?.dataSelector}
                      keys={autoGenerateByIdProps?.keys}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  label="Auto Generated Sort By"
                  name={['config', 'autoGenerateSortBy']}
                >
                  <AntdSelect
                    options={AUTO_GENERATED_SORT_BY_OPTIONS}
                    placeholder="Select sort by"
                  />
                </Form.Item>
              </>
            )}
            {[
              CONFIG_METHOD_TYPES.AUTO_GENERATED,
              CONFIG_METHOD_TYPES.RECOMMENDED
            ]?.includes(method) && (
              <>
                {isMultiPrayer && (
                  <Form.Item
                    label="No. of Auto generated Videos"
                    name={['config', 'autoGenerateLimit']}
                    rules={[
                      formValidatorRules?.number,
                      formValidatorRules?.maxNumberAllowed(
                        configData?.MAX_AUTO_GENERATE_LIMIT?.value || 20
                      )
                    ]}
                  >
                    <Input placeholder="Enter number" />
                  </Form.Item>
                )}
              </>
            )}
            {method === CONFIG_METHOD_TYPES.MANUAL && (
              <Form.Item
                label={isMultiPrayer ? 'Select Prayers' : 'Select Prayer'}
                name={['config', 'prayers']}
                extra={isMultiPrayer ? 'Select items in order you want' : ''}
              >
                <SelectPrayers multiple={isMultiPrayer} />
              </Form.Item>
            )}
          </div>
        </Space>
        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
};

export default PrayerForm;
