import { useMutation, useQuery } from '@apollo/client';
import { Select as AntdSelect, Button, Space, Tooltip, Typography } from 'antd';
import { find } from 'lodash';
import { ArrowClockwise } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import {
  CLOUD_FRONT_CACHE_KEYS,
  MODULES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import EmptyStatePage from '../../components/EmptyStatePage';
import PageHeader from '../../components/PageHeader';
import useCheckPermission from '../../hooks/useCheckPermission';
import { Select } from '../videos/components/FormInputs';
import { MENU_POSITIONS, MENU_POSITIONS_OPTIONS } from './AddEditMenu';
import Table from './components/Table';
import { INVALIDATE_CLOUD_FRONT_CACHE } from './graphql/Mutations';
import { GET_LANGUAGES, GET_MENUS } from './graphql/Queries';

const removeNestedItem = (data, parentIds, id) => {
  if (!parentIds.length) return data.filter((item) => item.id !== id);
  const parentId = parentIds.splice(0, 1)[0];

  return data.map((item) => {
    if (item.id === parentId) {
      return {
        ...item,
        menuItems: removeNestedItem(item.menuItems, parentIds, id)
      };
    }
    return item;
  });
};

const moveNestedItem = (data, parentIds, newMenuItems) => {
  if (!parentIds.length) return newMenuItems;
  const parentId = parentIds.splice(0, 1)[0];
  return data.map((item) => {
    if (item.id === parentId) {
      return {
        ...item,
        menuItems: moveNestedItem(item.menuItems, parentIds, newMenuItems)
      };
    }
    return item;
  });
};

const Menus = ({ history }) => {
  const [position, setPosition] = useState(MENU_POSITIONS.HEADER);
  const [menus, setMenus] = useState([]);
  const [language, setLanguage] = useState(null);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isDeleteAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.DELETE]
    }
  ]);

  const [invalidateCache, { loading: isInvalidating }] = useMutation(
    INVALIDATE_CLOUD_FRONT_CACHE,
    {
      variables: { key: CLOUD_FRONT_CACHE_KEYS.MENU }
    }
  );

  const { loading, refetch, data: menuData } = useQuery(GET_MENUS, {
    fetchPolicy: 'network-only',
    variables: { filter: { languageCode: language?.value } },
    onCompleted: ({ menusAdmin }) => {
      setMenus(
        menusAdmin?.menus?.filter((menu) => menu?.position === position) || []
      );
    }
  });

  useEffect(() => {
    const allMenus = menuData?.menusAdmin?.menus ?? [];
    if (allMenus) {
      setMenus(allMenus?.filter((menu) => menu?.position === position) || []);
    }
  }, [menuData, position]);

  const handleShowModal = () => {
    history.push(`${ROUTES?.MENUS}/add?position=${position}`);
  };

  const removeItem = (parentIds, id) => {
    setMenus((prev) => removeNestedItem(prev, [...parentIds], id));
  };

  const moveItem = (parentIds, menuItems) => {
    setMenus((prev) => moveNestedItem(prev, [...parentIds], menuItems));
  };

  const handlePositionChange = (value) => setPosition(value);
  const handleMoveSuccess = () => refetch();

  const handleLanguageChange = (_, newValue) => {
    setLanguage(newValue);
  };

  return (
    <>
      <PageHeader
        menu={MODULES?.UI_CONFIGURATION}
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed}
      />
      <div className="assets-filter-wrapper">
        <div className="asset-filter-left" />
        <div className="asset-filter-right">
          <div className="asset-filter">
            <Space size="small" wrap>
              <Typography.Text className="sort-by-title" type="secondary">
                Language
              </Typography.Text>
              <Select
                searchable={false}
                popupMatchSelectWidth={false}
                value={language}
                placeholder="Language"
                query={GET_LANGUAGES}
                onChange={handleLanguageChange}
                dataSelector={(data) =>
                  data?.languages?.languages?.map(({ id, code, name }) => ({
                    id,
                    label: name,
                    value: code
                  })) || []
                }
                keys={{
                  data: 'languages',
                  records: 'languages',
                  count: 'count'
                }}
                queryOptions={{
                  fetchPolicy: 'network-only',
                  onCompleted: ({ languages }) => {
                    const englishLang = find(
                      languages?.languages,
                      // by default english from backend
                      (item) => ['en', 'EN']?.includes(item?.code)
                    );
                    setLanguage({
                      label: englishLang?.name,
                      value: englishLang?.code
                    });
                  }
                }}
              />
              <Typography.Text className="sort-by-title" type="secondary">
                Position
              </Typography.Text>
              <AntdSelect
                popupMatchSelectWidth={false}
                value={position}
                options={MENU_POSITIONS_OPTIONS}
                onChange={handlePositionChange}
              />
              <Tooltip title="Revalidate cache" placement="left">
                <Button
                  className="text-btn d-flex align-center justify-center"
                  type="text"
                  icon={<ArrowClockwise size={24} />}
                  onClick={invalidateCache}
                  loading={isInvalidating}
                />
              </Tooltip>
            </Space>
          </div>
        </div>
      </div>
      <div className="ant-body-scroll assets-wrapper">
        {!loading && menus?.length <= 0 ? (
          <EmptyStatePage />
        ) : (
          <div className="listview nested-table">
            <Table
              loading={loading}
              data={menus}
              removeItem={removeItem}
              moveItem={moveItem}
              onMoveSuccess={handleMoveSuccess}
              isAddEditAllowed={isAddEditAllowed}
              isDeleteAllowed={isDeleteAllowed}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Menus;
