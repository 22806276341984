import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import {
  ColorPicker as AntdColorPicker,
  Select as AntdSelect,
  Button,
  Collapse,
  Form,
  Input,
  Modal,
  Space,
  Tooltip,
  Upload
} from 'antd';
import { groupBy, isArray } from 'lodash';
import { ArrowClockwise } from 'phosphor-react';
import React, { useCallback, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  ASSET_CATEGORY,
  DEFAULT_APP_COLORS,
  LOGO_POSITIONS,
  LOGO_SIZES,
  SHOW_MENU
} from '../../../common/constants';
import ProgressBar from '../../../components/ProgressBar';
import useDebounce from '../../../hooks/useDebounce';
import SelectAsset from '../../assets/components/SelectAsset';
import Components from '../../component';

const DEFAULT_COLOR = '#000000';

export function MobileView({ namePath, form }) {
  const colors = useDebounce(Form.useWatch(namePath, form));

  return <Components colors={colors} />;
}

function EmailVerification({ namePath }) {
  return (
    <Form.Item
      label="Email Verification Base URL"
      name={namePath}
      rules={[
        {
          type: 'url',
          message: 'Invalid URL'
        }
      ]}
    >
      <Input placeholder="Enter URL" />
    </Form.Item>
  );
}

function DefaultImage({
  namePath,
  handlePreview,
  uploadButton,
  defaultImageArray,
  defaultImageProgress
}) {
  return (
    <>
      <Form.Item
        name={namePath}
        label={
          <div className="d-flex">
            <p className="mr-6">Default Image (Recommended: 1600 * 900)</p>
            <Tooltip title="Used as default image or placeholder image for website in all modules">
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        }
        rules={[{ required: true, message: 'Please upload default image' }]}
        valuePropName="fileList"
        getValueFromEvent={(e) => {
          if (isArray(e)) {
            return e;
          }
          return e?.fileList;
        }}
      >
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          beforeUpload={() => false}
          maxCount={1}
          accept=".png"
          onPreview={handlePreview}
        >
          {defaultImageArray?.length > 0 ? null : uploadButton}
        </Upload>
      </Form.Item>
      {defaultImageProgress && defaultImageProgress >= 0 && (
        <Form.Item>
          <ProgressBar progress={defaultImageProgress} />
        </Form.Item>
      )}
    </>
  );
}

const FONT_TYPES = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  TERTIARY: 'TERTIARY'
};

const FONT_STYLES = {
  NORMAL: 'normal',
  ITALIC: 'italic',
  OBLIQUE: 'oblique'
};

const FONT_WEIGHTS = {
  NORMAL: 'normal',
  BOLD: 'bold',
  100: '100',
  200: '200',
  300: '300',
  400: '400',
  500: '500',
  600: '600',
  700: '700',
  800: '800',
  900: '900'
};

const FONT_TYPES_NAMES = {
  [FONT_TYPES.PRIMARY]: 'Primary',
  [FONT_TYPES.SECONDARY]: 'Secondary',
  [FONT_TYPES.TERTIARY]: 'Tertiary'
};

const FONT_TYPE_OPTIONS = [
  { label: 'Primary', value: FONT_TYPES.PRIMARY },
  { label: 'Secondary', value: FONT_TYPES.SECONDARY },
  { label: 'Tertiary', value: FONT_TYPES.TERTIARY }
];

const FONT_STYLE_OPTIONS = [
  { label: 'Normal', value: FONT_STYLES.NORMAL },
  { label: 'Italic', value: FONT_STYLES.ITALIC },
  { label: 'Oblique', value: FONT_STYLES.OBLIQUE }
];

const FONT_WEIGHT_OPTIONS = [
  { label: 'Normal', value: FONT_WEIGHTS.NORMAL },
  { label: 'Bold', value: FONT_WEIGHTS.BOLD },
  { label: '100', value: FONT_WEIGHTS['100'] },
  { label: '200', value: FONT_WEIGHTS['200'] },
  { label: '300', value: FONT_WEIGHTS['300'] },
  { label: '400', value: FONT_WEIGHTS['400'] },
  { label: '500', value: FONT_WEIGHTS['500'] },
  { label: '600', value: FONT_WEIGHTS['600'] },
  { label: '700', value: FONT_WEIGHTS['700'] },
  { label: '800', value: FONT_WEIGHTS['800'] },
  { label: '900', value: FONT_WEIGHTS['900'] }
];

function AddFontModal({ open, onClose, onSubmit }) {
  const [form] = Form.useForm();

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onOk={() => {
        form.submit();
      }}
      okText="Add"
      title="Add Font"
      afterOpenChange={(isOpen) => {
        if (!isOpen) {
          form?.resetFields();
        }
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        initialValues={{
          type: FONT_TYPES.PRIMARY,
          font: null,
          weight: FONT_WEIGHTS.NORMAL,
          style: FONT_STYLES.NORMAL
        }}
      >
        <Form.Item required label="Type" name="type">
          <AntdSelect options={FONT_TYPE_OPTIONS} />
        </Form.Item>
        <Form.Item
          label="Font"
          name="font"
          required
          rules={[
            {
              required: true,
              message: 'Please select font file'
            }
          ]}
        >
          <SelectAsset
            modalTitle="Select Font File"
            btnText="File"
            categoryKey={ASSET_CATEGORY.DOCUMENT}
            dataSelector={({ id, title }) => ({
              id,
              title
            })}
          />
        </Form.Item>
        <Form.Item label="Weight" name="weight">
          <AntdSelect options={FONT_WEIGHT_OPTIONS} />
        </Form.Item>
        <Form.Item label="Style" name="style">
          <AntdSelect options={FONT_STYLE_OPTIONS} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

function FontItem({ data, remove }) {
  return data?.map((font) => (
    <div key={font?.id} className="font-item">
      <span>{font?.font?.title}</span>
      <DeleteOutlined onClick={() => remove(font?.id)} />
    </div>
  ));
}

function Fonts({ namePath, form }) {
  const formFonts = Form.useWatch(namePath, form);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRemove = useCallback(
    (id) => {
      const oldValue = form?.getFieldValue(namePath) || [];
      const newValue = oldValue?.filter((font) => font?.id !== id);

      form?.setFieldValue(namePath, newValue);
    },
    [form]
  );

  const handleRemoveAll = useCallback(
    (type) => {
      const oldValue = form?.getFieldValue(namePath) || [];
      const newValue = oldValue?.filter((font) => font?.type !== type);

      form?.setFieldValue(namePath, newValue);
    },
    [form]
  );

  const fonts = useMemo(
    () =>
      Object.entries(groupBy(formFonts, 'type')).map(([key, value]) => ({
        key,
        label: FONT_TYPES_NAMES[key],
        children: <FontItem data={value} remove={handleRemove} />,
        extra: <DeleteOutlined onClick={() => handleRemoveAll(key)} />
      })),
    [formFonts, handleRemove, handleRemoveAll]
  );

  return (
    <Form.Item className="m-0" name={namePath}>
      <div className="config-form-item">
        <span className="config-item-label">Fonts</span>
        {fonts?.length > 0 && (
          <Collapse
            defaultActiveKey={Object.values(FONT_TYPES)}
            bordered={false}
            className="mb-12"
            expandIconPosition="start"
            items={fonts}
          />
        )}
        <Button onClick={handleOpen} icon={<PlusOutlined />}>
          Add Font
        </Button>
        <AddFontModal
          open={open}
          onClose={handleClose}
          onSubmit={(value) => {
            const oldValue = form?.getFieldValue(namePath) || [];
            const newValue = [...oldValue, { ...value, id: uuidv4() }];
            form?.setFieldValue(namePath, newValue);
            setOpen(false);
          }}
        />
      </div>
    </Form.Item>
  );
}

const APP_COLORS_SNIPPET = [
  {
    key: 'primary',
    title: 'Primary',
    colors: [
      {
        key: 'neutral1000',
        title: 'Neutral 1000',
        info: 'Header and article responsive background',
        defaultValue: DEFAULT_APP_COLORS.neutral1000
      },
      {
        key: 'neutral900',
        title: 'Neutral 900',
        info: 'Primary button',
        defaultValue: DEFAULT_APP_COLORS.neutral900
      },
      {
        key: 'neutral800',
        title: 'Neutral 800',
        info: 'Primary button hover',
        defaultValue: DEFAULT_APP_COLORS.neutral800
      },
      {
        key: 'neutral700',
        title: 'Neutral 700',
        info:
          'Featured video slider, featured video, and article carousel responsive background, menu background, hamburger toggle background',
        defaultValue: DEFAULT_APP_COLORS.neutral700
      },
      {
        key: 'neutral600',
        title: 'Neutral 600',
        info: '',
        defaultValue: DEFAULT_APP_COLORS.neutral600
      },
      {
        key: 'neutral500',
        title: 'Neutral 500',
        info: 'Carousel disable dot color',
        defaultValue: DEFAULT_APP_COLORS.neutral500
      }
    ]
  },
  {
    key: 'footer',
    title: 'Footer',
    colors: [
      {
        key: 'footerBg',
        title: 'Footer Background',
        info: '',
        defaultValue: DEFAULT_APP_COLORS.footerBg
      }
    ]
  },
  {
    key: 'accent',
    title: 'Accent',
    colors: [
      {
        key: 'neutral400',
        title: 'Neutral 400',
        info: 'Faq module background',
        defaultValue: DEFAULT_APP_COLORS.neutral400
      },
      {
        key: 'neutral300',
        title: 'Neutral 300',
        info: '',
        defaultValue: DEFAULT_APP_COLORS.neutral300
      },
      {
        key: 'neutral200',
        title: 'Neutral 200',
        info: 'Faq collapse background, secondary button for dark background',
        defaultValue: DEFAULT_APP_COLORS.neutral200
      },
      {
        key: 'neutral100',
        title: 'Neutral 100',
        info: 'Contact us form input background',
        defaultValue: DEFAULT_APP_COLORS.neutral100
      },
      {
        key: 'neutral0',
        title: 'Neutral 0',
        info: '',
        defaultValue: DEFAULT_APP_COLORS.neutral0
      }
    ]
  },
  {
    key: 'body',
    title: 'Body',
    colors: [
      {
        key: 'ivory',
        title: 'Body',
        info: '',
        defaultValue: DEFAULT_APP_COLORS.ivory
      }
    ]
  },
  {
    key: 'border',
    title: 'Border',
    colors: [
      {
        key: 'borderDark',
        title: 'Border Dark',
        info: '',
        defaultValue: DEFAULT_APP_COLORS.borderDark
      },
      {
        key: 'borderLight',
        title: 'Border Light',
        info: '',
        defaultValue: DEFAULT_APP_COLORS.borderLight
      }
    ]
  },
  {
    key: 'text_white',
    title: 'Text White',
    colors: [
      {
        key: 'white90',
        title: 'White 90',
        info: 'Text first light',
        defaultValue: DEFAULT_APP_COLORS.white90
      },
      {
        key: 'white80',
        title: 'White 80',
        info: 'Text second light',
        defaultValue: DEFAULT_APP_COLORS.white80
      },
      {
        key: 'white70',
        title: 'White 70',
        info: 'Text third light',
        defaultValue: DEFAULT_APP_COLORS.white70
      }
    ]
  },
  {
    key: 'text_black',
    title: 'Text Black',
    colors: [
      {
        key: 'black90',
        title: 'Black 90',
        info: 'Text first dark',
        defaultValue: DEFAULT_APP_COLORS.black90
      },
      {
        key: 'black80',
        title: 'Black 80',
        info: 'Text second dark',
        defaultValue: DEFAULT_APP_COLORS.black80
      },
      {
        key: 'black70',
        title: 'Black 70',
        info: 'Text third dark',
        defaultValue: DEFAULT_APP_COLORS.black70
      }
    ]
  },
  {
    key: 'red',
    title: 'Red',
    colors: [
      {
        key: 'red2',
        title: 'Red',
        info: '',
        defaultValue: DEFAULT_APP_COLORS.red2
      },
      {
        key: 'red',
        title: 'Red tint',
        info: '',
        defaultValue: DEFAULT_APP_COLORS.red
      },

      {
        key: 'red3',
        title: 'Red shade',
        info: '',
        defaultValue: DEFAULT_APP_COLORS.red3
      }
    ]
  },
  {
    key: 'yellow',
    title: 'Yellow',
    colors: [
      {
        key: 'yellow2',
        title: 'Yellow',
        info: '',
        defaultValue: DEFAULT_APP_COLORS.yellow2
      },
      {
        key: 'yellow',
        title: 'Yellow tint',
        info: '',
        defaultValue: DEFAULT_APP_COLORS.yellow
      },

      {
        key: 'yellow3',
        title: 'Yellow shade',
        info: '',
        defaultValue: DEFAULT_APP_COLORS.yellow3
      }
    ]
  },
  {
    key: 'blue',
    title: 'Blue',
    colors: [
      {
        key: 'blue2',
        title: 'Blue',
        info: '',
        defaultValue: DEFAULT_APP_COLORS.blue2
      },
      {
        key: 'blue',
        title: 'Blue tint',
        info: '',
        defaultValue: DEFAULT_APP_COLORS.blue
      },

      {
        key: 'blue3',
        title: 'Blue shade',
        info: '',
        defaultValue: DEFAULT_APP_COLORS.blue3
      }
    ]
  },
  {
    key: 'moss',
    title: 'Moss',
    colors: [
      {
        key: 'moss2',
        title: 'Moss',
        info: '',
        defaultValue: DEFAULT_APP_COLORS.moss2
      },
      {
        key: 'moss',
        title: 'Moss tint',
        info: '',
        defaultValue: DEFAULT_APP_COLORS.moss
      },

      {
        key: 'moss3',
        title: 'Moss shade',
        info: '',
        defaultValue: DEFAULT_APP_COLORS.moss3
      }
    ]
  }
];

const WEB_COLORS_SNIPPET = [
  {
    key: 'primary',
    title: 'Primary',
    colors: [
      {
        key: 'colorPrimary',
        title: 'Primary Color',
        defaultValue: DEFAULT_APP_COLORS.colorPrimary
      }
    ]
  },
  {
    key: 'text',
    title: 'Text',
    colors: [
      {
        key: 'colorTextBase',
        title: 'Text Color',
        defaultValue: DEFAULT_APP_COLORS.colorTextBase
      }
    ]
  },
  {
    key: 'primaryText',
    title: 'Primary Text',
    colors: [
      {
        key: 'colorPrimaryText',
        title: 'Primary Text Color',
        defaultValue: DEFAULT_APP_COLORS.colorPrimaryText
      }
    ]
  },
  {
    key: 'border',
    title: 'Border',
    colors: [
      {
        key: 'colorBorder',
        title: 'Border Color',
        defaultValue: DEFAULT_APP_COLORS.colorBorder
      }
    ]
  },
  {
    key: 'background',
    title: 'Background',
    colors: [
      {
        key: 'colorBgBase',
        title: 'Background Color',
        defaultValue: DEFAULT_APP_COLORS.colorBgBase
      }
    ]
  },
  {
    key: 'container',
    title: 'Container',
    colors: [
      {
        key: 'colorBgContainer',
        title: 'Container Color',
        defaultValue: DEFAULT_APP_COLORS.colorBgContainer
      }
    ]
  }
];

function ColorSnippetPicker({ onChange, ...rest }) {
  return (
    <AntdColorPicker
      format="hex"
      onChange={(_, hex) => onChange(hex)}
      {...rest}
    />
  );
}

function ColorSnippet({ data, namePath }) {
  return (
    <Space wrap size={8}>
      {data?.map((color) => {
        const name = [...namePath, color?.key];
        return (
          <Form.Item className="m-0" key={color?.key} name={name}>
            <ColorSnippetPicker
              showText={() => (
                <span className="color-picker-text">
                  {color?.title}{' '}
                  {color?.info ? (
                    <Tooltip title={color?.info}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  ) : null}
                </span>
              )}
            />
          </Form.Item>
        );
      })}
    </Space>
  );
}

function Colors({ namePath, form, appType }) {
  const colorSnippet =
    appType?.value === 'WEB_APP' ? WEB_COLORS_SNIPPET : APP_COLORS_SNIPPET;
  const colors = useMemo(
    () =>
      colorSnippet.map((snippet) => {
        return {
          key: snippet?.key,
          label: snippet?.title,
          children: <ColorSnippet data={snippet?.colors} namePath={namePath} />,
          extra: (
            <Tooltip title="Reset">
              <ArrowClockwise
                size={16}
                onClick={(e) => {
                  e?.stopPropagation();
                  const colorsPaths = snippet?.colors?.map(({ key }) => [
                    ...namePath,
                    key
                  ]);
                  if (colorsPaths?.length) {
                    form?.resetFields([...colorsPaths]);
                  }
                }}
              />
            </Tooltip>
          )
        };
      }),
    [form, appType, colorSnippet]
  );

  return (
    <div className="config-form-item">
      <span className="config-item-label">Colors</span>
      <Collapse
        defaultActiveKey={colorSnippet.map(({ key }) => key)}
        bordered={false}
        className="mb-12"
        expandIconPosition="start"
        items={colors}
      />
    </div>
  );
}

export const DEFAULT_CONFIG_KEYS = {
  EMAIL_VERIFICATION_BASE_URL: 'EMAIL_VERIFICATION_BASE_URL',
  FONTS: 'FONTS',
  COLORS: 'COLORS',
  APP_IMAGE_SIZE: 'APP_IMAGE_SIZE',
  APP_IMAGE_POSITION: 'APP_IMAGE_POSITION',
  DEFAULT_IMAGE: 'DEFAULT_DISPLAY_IMAGE',
  SHOW_MENU: 'SHOW_MENU'
};

export const DEFAULT_CONFIGS = {
  [DEFAULT_CONFIG_KEYS.DEFAULT_IMAGE]: {
    component: DefaultImage,
    name: 'defaultImage'
  },
  [DEFAULT_CONFIG_KEYS.EMAIL_VERIFICATION_BASE_URL]: {
    component: EmailVerification,
    name: 'emailVerificationBaseURL'
  },
  [DEFAULT_CONFIG_KEYS.FONTS]: {
    component: Fonts,
    name: 'fonts'
  },
  [DEFAULT_CONFIG_KEYS.COLORS]: {
    component: Colors,
    name: 'colors'
  },
  [DEFAULT_CONFIG_KEYS.APP_IMAGE_POSITION]: {
    name: 'logoPosition'
  },
  [DEFAULT_CONFIG_KEYS.APP_IMAGE_SIZE]: {
    name: 'logoSize'
  },
  [DEFAULT_CONFIG_KEYS.SHOW_MENU]: {
    name: 'showMenu'
  }
};

const additionalWebColors = {
  colorPrimary: '#1677FF',
  colorBgBase: '#FFFFFF',
  colorTextBase: '#000000',
  colorBgContainer: '#F5F5F5',
  colorPrimaryText: '#AAAAAA',
  colorBorder: '#E8E9EA'
};

export const getConfigData = (
  config,
  isApp = false,
  allowedKeys = Object.values(DEFAULT_CONFIG_KEYS)
) => {
  if (!config) return [];

  const newConfig = [
    {
      key: DEFAULT_CONFIG_KEYS.EMAIL_VERIFICATION_BASE_URL,
      value: config?.emailVerificationBaseURL || '',
      type: 'PROTECTED',
      dataType: 'STRING'
    },
    {
      key: DEFAULT_CONFIG_KEYS.COLORS,
      value: isApp
        ? config?.colors
        : { ...config?.colors, ...additionalWebColors } || {},
      type: 'PUBLIC',
      dataType: 'JSON'
    },
    {
      key: DEFAULT_CONFIG_KEYS.FONTS,
      value:
        config?.fonts?.map((font) => ({
          type: font?.type,
          fontId: font?.font?.id,
          weight: font?.weight,
          style: font?.style
        })) || [],
      type: 'PUBLIC',
      dataType: 'JSON'
    },
    {
      key: DEFAULT_CONFIG_KEYS.APP_IMAGE_SIZE,
      value: config?.logoSize || LOGO_SIZES.SMALL,
      type: 'PUBLIC',
      dataType: 'STRING'
    },
    {
      key: DEFAULT_CONFIG_KEYS.APP_IMAGE_POSITION,
      value: config?.logoPosition || LOGO_POSITIONS.CENTER,
      type: 'PUBLIC',
      dataType: 'STRING'
    },
    {
      key: DEFAULT_CONFIG_KEYS.DEFAULT_IMAGE,
      value: config?.defaultImage || '',
      type: 'PUBLIC',
      dataType: 'STRING'
    },
    {
      key: DEFAULT_CONFIG_KEYS.SHOW_MENU,
      value: config?.showMenu || SHOW_MENU.NO,
      type: 'PUBLIC',
      dataType: 'STRING'
    }
  ];

  return newConfig?.filter(({ key }) => allowedKeys?.includes(key));
};

export const parseConfigData = (configs) => {
  if (!configs?.length) return null;

  const config = {};

  configs?.forEach((item) => {
    const defaultConfig = DEFAULT_CONFIGS?.[item?.key];
    if (defaultConfig) {
      switch (item?.key) {
        case DEFAULT_CONFIG_KEYS.EMAIL_VERIFICATION_BASE_URL: {
          config[defaultConfig?.name] = item?.value || '';
          break;
        }
        case DEFAULT_CONFIG_KEYS.COLORS:
          config[defaultConfig?.name] = item?.value || DEFAULT_COLOR;
          break;
        case DEFAULT_CONFIG_KEYS.FONTS: {
          config[defaultConfig?.name] =
            item?.fonts?.map((font) => ({
              ...font,
              id: uuidv4()
            })) || [];
          break;
        }
        case DEFAULT_CONFIG_KEYS.APP_IMAGE_SIZE:
          config[defaultConfig?.name] = item?.value || LOGO_SIZES.MEDIUM;
          break;
        case DEFAULT_CONFIG_KEYS.APP_IMAGE_POSITION:
          config[defaultConfig?.name] = item?.value || LOGO_POSITIONS.CENTER;
          break;
        case DEFAULT_CONFIG_KEYS.DEFAULT_IMAGE:
          config[defaultConfig?.name] = item?.value
            ? [{ url: item?.value }]
            : [];
          break;
        case DEFAULT_CONFIG_KEYS.SHOW_MENU:
          config[defaultConfig?.name] = item?.value || SHOW_MENU.NO;
          break;
        default:
          break;
      }
    }
  });

  return config;
};
